import React, { useEffect, useState } from "react";
import Select from "react-select";
import { customNoOptionsMessage } from "../../../utils/option-not-found";
import selectStyle from "../../../utils/select-styles";

function FilterSelectSearchs({
  title,
  setFilterState,
  nameInput,
  filter,
  selectArray,
  readyArray,
  required,
}) {
  const [arrays, setArrays] = useState([]);
  const [inputLength, setInputLength] = useState(0);
  const [citySelect, setCitySelect] = useState(null);

  const onChangeCity = (event) => {
    let { label, value } = event;
    label = label.split(",")[0];

    const newCity = { label, value };

    setArrays((prevCitys) => {
      const isCityExist = prevCitys.some((city) => city.value === value);
      if (!isCityExist) {
        return [...prevCitys, newCity];
      }
      return prevCitys;
    });
  };

  useEffect(() => {
    setFilterState((prevState) => ({
      ...prevState,
      [nameInput]: arrays.map((city) => city.value),
    }));
  }, [arrays]);

  const onInputChange = (value) => {
    setInputLength(value.length);
    if (inputLength > 2) {
      cityOptions.filter((item) => item.label.includes(value));
    }
  };
  const cityOptions = filter ? selectArray(filter, [nameInput]) : [];

  const handleRemoveCity = (valueToRemove) => {
    setArrays((prevCitys) =>
      prevCitys.filter((city) => city.value !== valueToRemove)
    );
  };
  useEffect(() => {
    setArrays(selectArray(readyArray, [nameInput]));
  }, [readyArray]);
  return (
    <div className="main__filter-item">
      {title && <span>{title}</span>}
      <div className="search-field">
        <Select
          className="input-style"
          options={cityOptions}
          styles={selectStyle}
          placeholder="Введите хотя бы 3 символа"
          noOptionsMessage={customNoOptionsMessage}
          name="filter_city"
          value={citySelect}
          defaultValue={citySelect}
          onChange={onChangeCity}
          onInputChange={onInputChange}
          required={required}
        />
      </div>
      {arrays.length !== 0 && (
        <div className="search-tag-list">
          {arrays.map((item) => {
            return (
              <span>
                {item.label}
                <button onClick={() => handleRemoveCity(item.value)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M5.58731 5.00002L8.21231 2.37918C8.29077 2.30072 8.33485 2.19431 8.33485 2.08335C8.33485 1.97239 8.29077 1.86598 8.21231 1.78752C8.13385 1.70906 8.02744 1.66498 7.91648 1.66498C7.80552 1.66498 7.69911 1.70906 7.62065 1.78752L4.99981 4.41252L2.37898 1.78752C2.30052 1.70906 2.1941 1.66498 2.08315 1.66498C1.97219 1.66498 1.86577 1.70906 1.78731 1.78752C1.70885 1.86598 1.66477 1.97239 1.66477 2.08335C1.66477 2.19431 1.70885 2.30072 1.78731 2.37918L4.41231 5.00002L1.78731 7.62085C1.74826 7.65958 1.71726 7.70567 1.69611 7.75644C1.67495 7.80722 1.66406 7.86168 1.66406 7.91668C1.66406 7.97169 1.67495 8.02615 1.69611 8.07692C1.71726 8.1277 1.74826 8.17378 1.78731 8.21252C1.82605 8.25157 1.87213 8.28257 1.9229 8.30372C1.97368 8.32487 2.02814 8.33577 2.08315 8.33577C2.13815 8.33577 2.19261 8.32487 2.24339 8.30372C2.29416 8.28257 2.34024 8.25157 2.37898 8.21252L4.99981 5.58752L7.62065 8.21252C7.65938 8.25157 7.70546 8.28257 7.75624 8.30372C7.80701 8.32487 7.86147 8.33577 7.91648 8.33577C7.97148 8.33577 8.02594 8.32487 8.07672 8.30372C8.12749 8.28257 8.17358 8.25157 8.21231 8.21252C8.25136 8.17378 8.28236 8.1277 8.30352 8.07692C8.32467 8.02615 8.33556 7.97169 8.33556 7.91668C8.33556 7.86168 8.32467 7.80722 8.30352 7.75644C8.28236 7.70567 8.25136 7.65958 8.21231 7.62085L5.58731 5.00002Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </span>
            );
          })}
        </div>
      )}
      {/* {readyArray.length !== 0 && (
        <div className="search-tag-list">
          {readyArray.map((item) => {
            return (
              <span>
                {item}
                <button type="button" onClick={() => handleRemoveCity(item)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M5.58731 5.00002L8.21231 2.37918C8.29077 2.30072 8.33485 2.19431 8.33485 2.08335C8.33485 1.97239 8.29077 1.86598 8.21231 1.78752C8.13385 1.70906 8.02744 1.66498 7.91648 1.66498C7.80552 1.66498 7.69911 1.70906 7.62065 1.78752L4.99981 4.41252L2.37898 1.78752C2.30052 1.70906 2.1941 1.66498 2.08315 1.66498C1.97219 1.66498 1.86577 1.70906 1.78731 1.78752C1.70885 1.86598 1.66477 1.97239 1.66477 2.08335C1.66477 2.19431 1.70885 2.30072 1.78731 2.37918L4.41231 5.00002L1.78731 7.62085C1.74826 7.65958 1.71726 7.70567 1.69611 7.75644C1.67495 7.80722 1.66406 7.86168 1.66406 7.91668C1.66406 7.97169 1.67495 8.02615 1.69611 8.07692C1.71726 8.1277 1.74826 8.17378 1.78731 8.21252C1.82605 8.25157 1.87213 8.28257 1.9229 8.30372C1.97368 8.32487 2.02814 8.33577 2.08315 8.33577C2.13815 8.33577 2.19261 8.32487 2.24339 8.30372C2.29416 8.28257 2.34024 8.25157 2.37898 8.21252L4.99981 5.58752L7.62065 8.21252C7.65938 8.25157 7.70546 8.28257 7.75624 8.30372C7.80701 8.32487 7.86147 8.33577 7.91648 8.33577C7.97148 8.33577 8.02594 8.32487 8.07672 8.30372C8.12749 8.28257 8.17358 8.25157 8.21231 8.21252C8.25136 8.17378 8.28236 8.1277 8.30352 8.07692C8.32467 8.02615 8.33556 7.97169 8.33556 7.91668C8.33556 7.86168 8.32467 7.80722 8.30352 7.75644C8.28236 7.70567 8.25136 7.65958 8.21231 7.62085L5.58731 5.00002Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </span>
            );
          })}
        </div>
      )} */}
    </div>
  );
}

export default FilterSelectSearchs;
