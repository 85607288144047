import { createAsyncThunk } from "@reduxjs/toolkit";
import profileService from "../profileService";

export const profileDeleteAdditional = createAsyncThunk(
  "profile_additional/delete",
  async (data, thunkAPI) => {
    try {
      return await profileService.profileDeleteAdditional(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//
