import React from "react";
import bussiness from "../../../images/bussiness.svg";
import chat from "../../../images/chat.svg";
import fire from "../../../images/fire.svg";
import garage from "../../../images/garage.svg";
import tag from "../../../images/tag.svg";
import transport from "../../../images/transport.svg";

function ProBaner() {
  return (
    <div className="side-bar-banner profile-pro-baner">
      <h2 className="side-bar-banner__title">
        Получите статус <span>Pro</span> и разблокируйте выгодные функции
        платформы.
      </h2>
      <div className="profile-pro-baner__list">
        <div>
          <img src={transport} alt="" />
          Больше предложений
        </div>
        <div>
          <img src={bussiness} alt="" />
          Персональные скидки
        </div>
        <div>
          <img src={fire} alt="" />
          Ранний доступ
        </div>
      </div>

      <h2 className="side-bar-banner__title">
        Внесите депозит и зарабатывайте по максимуму.
      </h2>
      <div className="profile-pro-baner__list">
        <div>
          <img src={tag} alt="" />
          Доступ к аукциона
        </div>
        <div>
          <img src={chat} alt="" />
          Согласование стоимости
        </div>
        <div>
          <img src={garage} alt="" />
          Резервирование
        </div>
      </div>
      <a
        href="https://forms.yandex.ru/cloud/66b1fea3068ff0b75ad7dad2/"
        data-text="Стать PRO"
        target="_blank"
      >
        Стать PRO
      </a>
    </div>
  );
}

export default ProBaner;
