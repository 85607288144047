import axios from "axios";

const API_URL = "/v1/filter";

// Lots Store
const filterIndex = async (query) => {
  const response = await axios.get(API_URL, {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

const filterService = {
  filterIndex,
};

export default filterService;
