import React, { useState } from "react";
import noimage from "../../images/no-image.png";
import { extractVideoID } from "../../utils/youtube-id";
import youtube_icon from "../../images/youtube_icon.png";
import { Gallery, Item } from "react-photoswipe-gallery";

function SingleGallery({ single }) {
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const handleShowAllPhotos = () => {
    setShowAllPhotos(true);
  };

  const photosToShow = showAllPhotos
    ? single?.attachments
    : single?.attachments.slice(1, 16);
  let youtube_link_id = "";
  let youtube_link__diagnostic_id = "";
  const uiElements = [
    {
      name: "bulletsIndicator",
      order: 9,
      isButton: false,
      appendTo: "wrapper",
      onInit: (el, pswpInstance) => {
        let prevIndex = -1;
        const thumbnails = [];

        el.style.position = "absolute";
        el.style.bottom = "20px";
        el.style.left = "10px";
        el.style.right = "0";
        el.style.display = "flex";
        el.style.gridGap = "10px";
        el.style.gridTemplateColumns = "repeat(auto-fit, 40px)";
        el.style.gridTemplateRows = "repeat(auto-fit, 40px)";
        el.style.justifyContent = "center";

        const dataSource = pswpInstance.options.dataSource;

        for (let i = 0; i < dataSource.length; i++) {
          const slideData = dataSource[i];

          const thumbnailUrl = slideData.src ? slideData.msrc : youtube_icon;

          const thumbnail = document.createElement("div");
          thumbnail.style.transition = "transform 0.15s ease-in";
          thumbnail.style.opacity = "0.6";
          thumbnail.style.cursor = "pointer";
          thumbnail.style.width = "30px";
          thumbnail.onclick = (e) => {
            const target = e.target;
            const thumbnailEl =
              target.tagName === "IMG" ? target.parentElement : target;
            if (thumbnailEl) {
              pswpInstance.goTo(thumbnails.indexOf(thumbnailEl));
            }
          };

          const thumbnailImage = document.createElement("img");
          thumbnailImage.setAttribute("src", thumbnailUrl || "");
          thumbnailImage.style.width = "100%";
          thumbnailImage.style.height = "100%";
          thumbnailImage.style.objectFit = "contain";

          thumbnail.appendChild(thumbnailImage);
          el.appendChild(thumbnail);
          thumbnails.push(thumbnail);
        }

        pswpInstance.on("change", () => {
          if (prevIndex >= 0) {
            const prevThumbnail = thumbnails[prevIndex];
            prevThumbnail.style.opacity = "0.6";
            prevThumbnail.style.cursor = "pointer";
            prevThumbnail.style.transform = "scale(1)";
          }

          const currentThumbnail = thumbnails[pswpInstance.currIndex];
          currentThumbnail.style.opacity = "1";
          currentThumbnail.style.cursor = "unset";
          currentThumbnail.style.transform = "scale(1.2)";

          prevIndex = pswpInstance.currIndex;
        });
      },
    },
  ];

  if (single?.youtube_link) {
    youtube_link_id = extractVideoID(single?.youtube_link);
  }

  if (single?.youtube_link_diagnostic) {
    youtube_link__diagnostic_id = extractVideoID(
      single?.youtube_link_diagnostic
    );
  }

  const Map = ({ id }) => {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <iframe
          style={{
            background: "#444",
            width: "100%",
            height: "100%",
            maxWidth: "800px",
            maxHeight: "400px",
            pointerEvents: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src={`https://www.youtube.com/embed/${id}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    );
  };

  return (
    <div className="row__item">
      {single?.attachments.length !== 0 ? (
        <>
          <Gallery uiElements={uiElements}>
            <Item
              original={single?.attachments[0]?.url}
              thumbnail={single?.attachments[0]?.url}
              width="1024"
              height="768"
            >
              {({ ref, open }) => (
                <img
                  src={single?.attachments[0]?.url}
                  alt=""
                  className="row__img mt-0"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <div className="detail__list-images mt-3">
              {single?.youtube_link && (
                <Item content={<Map id={youtube_link_id} />}>
                  {({ ref, open }) => (
                    <div
                      className="detail__item-img detail__video"
                      onClick={open}
                    >
                      <img
                        src={`https://i.ytimg.com/vi/${youtube_link_id}/hqdefault.jpg`}
                        alt=""
                        ref={ref}
                        onClick={open}
                      />
                      <span>Тест-драйв</span>
                    </div>
                  )}
                </Item>
              )}
              {single?.youtube_link_diagnostic && (
                <Item content={<Map id={youtube_link__diagnostic_id} />}>
                  {({ ref, open }) => (
                    <div
                      className="detail__item-img detail__video"
                      onClick={open}
                    >
                      <img
                        src={`https://i.ytimg.com/vi/${youtube_link__diagnostic_id}/hqdefault.jpg`}
                        alt=""
                        ref={ref}
                        onClick={open}
                      />
                      <span>Диагностика</span>
                    </div>
                  )}
                </Item>
              )}

              {photosToShow &&
                photosToShow.map((attachment, index) => {
                  return (
                    <Item
                      original={attachment.url}
                      thumbnail={attachment.thumbnail_url}
                      width="1024"
                      height="768"
                      key={index}
                    >
                      {({ ref, open }) => (
                        <div className="detail__item-img" onClick={open}>
                          <img src={attachment.url} alt="" ref={ref} />
                        </div>
                      )}
                    </Item>
                  );
                })}
            </div>
          </Gallery>
          {!showAllPhotos && single?.attachments.slice(16).length !== 0 && (
            <button
              className="gradient-btn-blue w-100 mt-3"
              onClick={handleShowAllPhotos}
            >
              Все фото ({single?.attachments.slice(16).length})
            </button>
          )}
        </>
      ) : (
        <img src={noimage} className="row__img mt-0" />
      )}
    </div>
  );
}

export default SingleGallery;
