import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import noimage from "../../../images/no-image.png";
import { chatGet } from "../../../store/chat/сhatIndexSlice/chatIndexSlice";

import { getDatabase, off, onValue, ref } from "firebase/database";
import { chatsListManegerSingle } from "../../../store/chat/chatListMenegerSingleSlice/chatListMenegerSingleSlice";
import { сhatManagerSend } from "../../../store/chat/сhatManagerSendSlice/сhatManagerSendSlice";
import { сhatSend } from "../../../store/chat/сhatSendSlice/сhatSendSlice";
import MessageList from "./../../../components/chat/chat-create-component/message-list";

function ChatCreate() {
  const { user } = useSelector((state) => state.profileGet);
  const { chats } = useSelector((state) => state.chats);
  const { manager_single_chat } = useSelector(
    (state) => state.singleManagerChat
  );

  const chat_single = ["Admin", "Manager"].includes(user?.data?.role)
    ? manager_single_chat
    : chats;

  const { isLoading } = useSelector((state) => state.chatsCreate);

  const { car_id } = useParams();
  useEffect(() => {
    if (!chat_single?.data?.chat_room_id) {
      return;
    }
    const database = getDatabase();
    const knot = `chat_room/${user?.data.user[0].id}/${chat_single.data.chat_room_id}`;

    const messagesRef = ref(database, knot);
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      if (snapshot.exists()) {
        dispatch(
          ["Admin", "Manager"].includes(user?.data?.role)
            ? chatsListManegerSingle(car_id)
            : chatGet(car_id)
        );
      }
    });

    // Очистка слушателя при размонтировании компонента или изменении зависимостей
    return () => {
      off(messagesRef);
    };
  }, [chat_single?.data?.chat_room_id]);

  const dispatch = useDispatch();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat_single]);

  const sendApi = (message) => {
    ["Admin", "Manager"].includes(user?.data?.role)
      ? dispatch(
          сhatManagerSend({ id: car_id, data: { content: message } })
        ).then((res) => {
          if (res.payload.status === 201) {
            dispatch(chatsListManegerSingle(car_id));
          }
        })
      : dispatch(сhatSend({ id: car_id, data: { content: message } })).then(
          (res) => {
            if (res.payload.status === 201) {
              dispatch(chatGet(car_id));
            }
          }
        );
  };

  return (
    <div className="chat-collumn-two">
      <div className="chat-list-message chat-window">
        <div className="chat__header">
          <div>
            {chat_single?.data?.thumbnail ? (
              <img src={chat_single?.data?.thumbnail} alt="" />
            ) : (
              <img src={noimage} className="row__img mt-0" />
            )}
          </div>
          <div className="chat__header--title">
            <div className="d-flex ">
              {user?.data.user[0].name !== ""
                ? user?.data.user[0].name
                : user?.data.user[0].phone}
            </div>
            {![4, 5, 6, 7, 8, 9].includes(chat_single?.data?.status_id) && (
              <Link to={`/applications/${chat_single?.data?.model_id}`}>
                {chat_single?.data?.title}
              </Link>
            )}
          </div>
        </div>
        <MessageList
          chats={chat_single}
          postChatMessage={sendApi}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default ChatCreate;
