import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import applicationAdminService from "../applicationAdminService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Chat  Get

export const applicationsAdminUpdate = createAsyncThunk(
  "admin/applicationsAdminUpdate",
  async ({ id, data }, thunkAPI) => {
    try {
      return await applicationAdminService.applicationsAdminUpdate(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const applicationsAdminUpdateSlice = createSlice({
  name: "applicationsAdminUpdate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applicationsAdminUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsAdminUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(applicationsAdminUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export default applicationsAdminUpdateSlice.reducer;
