import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { filterIndex } from "../../store/filter/filterIndexSlice/filterIndexSlice";
import { setfilterQuerySlice } from "../../store/filter/filterQuerySlice/filterQuerySlice";
import { selectCity, selectMark } from "../../utils/select-array";
import Preloader from "../prelouder/prelouder";
import FilterChecked from "./filter-checkbox/filter-checkbox";
import FilterSelectSearchs from "./filter-select-search/filter-select-search";

function Filter({
  openFilter,
  setOpenFilter,
  status_id,
  getData,
  query,
  count,
  isLoading,
  auction,
  type,
}) {
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.filter);

  const [filterState, setFilterState] = useState(() => {
    const initialState = { onlyCount: true, ...query };
    if (status_id !== undefined) {
      initialState.status = status_id;
    }

    return initialState;
  });
  const isInitialMount = useRef(true);

  const debouncedDispatch = useCallback(
    debounce((dispatch, filterState) => {
      dispatch(getData(filterState));
    }, 100),
    []
  );

  useEffect(() => {
    debouncedDispatch(dispatch, filterState);
  }, [filterState, debouncedDispatch]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (openFilter) {
      dispatch(filterIndex(type));
    }
  }, [openFilter]);
  console.log(filterState);

  const onFilter = () => {
    dispatch(setfilterQuerySlice({ ...filterState }));
    dispatch(getData({ ...filterState, onlyCount: false }))
      .then((res) => {
        toast.success("Фильтрация прошла успешно");
      })
      .catch((error) => {
        toast.error(
          "При фильтрации возникла ошибка , пожалуйста попробуйте позже!"
        );
      });
  };

  const resetFilter = () => {
    dispatch(getData())
      .then((res) => {
        toast.success("Фильтр успешно сброшен!");
      })
      .catch((error) => {
        toast.error("Произошла ошибка попробуйте позже");
      });
  };

  return (
    <div className={`main__filter ${openFilter && "active"}`}>
      <div className="main__filter-head">
        <span className="main__filter-title">Фильтр</span>
        <button
          type="button"
          className="main__filter-btn-close"
          onClick={() => {
            setOpenFilter(!openFilter);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.9398 8L13.1398 3.80667C13.2653 3.68113 13.3359 3.51087 13.3359 3.33333C13.3359 3.1558 13.2653 2.98554 13.1398 2.86C13.0143 2.73447 12.844 2.66394 12.6665 2.66394C12.4889 2.66394 12.3187 2.73447 12.1931 2.86L7.9998 7.06L3.80646 2.86C3.68093 2.73447 3.51066 2.66394 3.33313 2.66394C3.1556 2.66394 2.98533 2.73447 2.8598 2.86C2.73426 2.98554 2.66374 3.1558 2.66374 3.33333C2.66374 3.51087 2.73426 3.68113 2.8598 3.80667L7.0598 8L2.8598 12.1933C2.79731 12.2553 2.74771 12.329 2.71387 12.4103C2.68002 12.4915 2.6626 12.5787 2.6626 12.6667C2.6626 12.7547 2.68002 12.8418 2.71387 12.9231C2.74771 13.0043 2.79731 13.078 2.8598 13.14C2.92177 13.2025 2.99551 13.2521 3.07675 13.2859C3.15798 13.3198 3.24512 13.3372 3.33313 13.3372C3.42114 13.3372 3.50827 13.3198 3.58951 13.2859C3.67075 13.2521 3.74449 13.2025 3.80646 13.14L7.9998 8.94L12.1931 13.14C12.2551 13.2025 12.3288 13.2521 12.4101 13.2859C12.4913 13.3198 12.5785 13.3372 12.6665 13.3372C12.7545 13.3372 12.8416 13.3198 12.9228 13.2859C13.0041 13.2521 13.0778 13.2025 13.1398 13.14C13.2023 13.078 13.2519 13.0043 13.2857 12.9231C13.3196 12.8418 13.337 12.7547 13.337 12.6667C13.337 12.5787 13.3196 12.4915 13.2857 12.4103C13.2519 12.329 13.2023 12.2553 13.1398 12.1933L8.9398 8Z"
              fill="#8D8796"
            />
          </svg>
        </button>
      </div>
      {filter && Object.keys(filter).length !== 0 ? (
        <>
          {/* Тип авто */}
          <FilterChecked
            filter={filter?.data?.type}
            title="Тип авто "
            setFilterState={setFilterState}
            nameInput="filter_type"
          />
          {/*  */}
          {/* Состояние авто */}
          <FilterChecked
            filter={filter?.data?.auto_cat}
            title="Состояние авто"
            setFilterState={setFilterState}
            nameInput="filter_auto_cat"
          />
          {/*  */}
          {auction && (
            <div className="main__filter-item">
              <h4>Дата окончания аукциона</h4>
              <label className="input-date-wrap">
                <span>до</span>
                <input
                  type="date"
                  className="input-style"
                  name="end_auction"
                  onChange={(e) =>
                    setFilterState({ ...query, end_auction: e.target.value })
                  }
                />
              </label>
            </div>
          )}

          {/* Марки авто */}
          <FilterSelectSearchs
            filter={filter?.data?.marks}
            title="Марки авто"
            setFilterState={setFilterState}
            nameInput="filter_mark"
            selectArray={selectMark}
          />
          {/*  */}
          {/* Город размещения */}
          <FilterSelectSearchs
            filter={filter?.data?.cites}
            title="Город размещения"
            setFilterState={setFilterState}
            nameInput="filter_city"
            selectArray={selectCity}
          />
          {/*  */}

          {isLoading ? (
            <div className="gradient-btn-blue w-100 mt-auto">
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Фильтрация</span>
            </div>
          ) : (
            <button
              type="button"
              className="gradient-btn-blue w-100 mt-auto"
              onClick={onFilter}
            >
              Показать {count} предложения
            </button>
          )}
          <button
            type="button"
            className="refresh-filter"
            onClick={resetFilter}
          >
            Сбросить фильтр
          </button>
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
}

export default Filter;
