import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationService from "../applicationsService";

const initialState = {
  count: 0,
  applications: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Profile get

export const applicationsGuestGet = createAsyncThunk(
  "applications-guest/get",
  async (query, thunkAPI) => {
    try {
      return await applicationService.applicationsGuest(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const applicationsGuestSlice = createSlice({
  name: "applicationsGuest",
  initialState,
  reducers: {
    applicationReset: (state) => {
      state.applications = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applicationsGuestGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsGuestGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.applications = action.payload.data.filter
          ? state.applications
          : action.payload;
        state.count = action.payload.data.total;
      })
      .addCase(applicationsGuestGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.applications = null;
      });
  },
});

export const { applicationReset } = applicationsGuestSlice.actions;

export default applicationsGuestSlice.reducer;
