import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import auctionService from "../auctionService";

const initialState = {
  greadeData: {},
  count: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const greade = createAsyncThunk(
  "auction/greade",
  async (query, thunkAPI) => {
    try {
      return await auctionService.auctionGreade(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const greadeSlice = createSlice({
  name: "greadeSlice",
  initialState,
  reducers: {
    greadeReset: (state) => {
      state.greadeData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(greade.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(greade.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.greadeData = action.payload.data.filter
          ? state.greadeData
          : action.payload;
        state.count = action.payload.data.total;
      })
      .addCase(greade.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.greadeData = null;
      });
  },
});

export const { greadeReset } = greadeSlice.actions;

export default greadeSlice.reducer;
