import axios from "axios";

const API_URL = "/v1/organization";

// organizationList
const organizationList = async () => {
  const response = await axios.get(API_URL);
  return { data: response.data, status: response.status };
};
//

const organizationService = {
  organizationList,
};

export default organizationService;
