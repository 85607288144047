import axios from "axios";

const API_URL = "/v1/law";

// law Store
const lawStore = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

// law Index
const lawShow = async (id) => {
  const response = await axios.get(API_URL + "/" + id);
  return { data: response.data, status: response.status };
};
//

const lawService = {
  lawStore,
  lawShow,
};

export default lawService;
