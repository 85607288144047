import axios from "axios";

const API_URL = "/v3/bargaining";

// Bargaining
const auctionBargaining = async (query) => {
  const response = await axios.get(API_URL, {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

// Greade
const auctionGreade = async (query) => {
  const response = await axios.get("/v2/greade", {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

// Bargaining price
const bargainingPrice = async (data) => {
  const response = await axios.post("/v1/bargaining/price", data);
  return { data: response.data, status: response.status };
};
//

// single
const single = async (id) => {
  const response = await axios.get("/v2/greade/" + id);
  return { data: response.data, status: response.status };
};
//

const auctionService = {
  auctionBargaining,
  auctionGreade,
  single,
  bargainingPrice,
};

export default auctionService;
