import React from "react";
import Header from "../../components/header/header";
import Sidebar from "../../components/sidebar/sidebar";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { profile } from "../../store/profile/profileGetSlice/profileGetSlice";

function Profile() {
  const { user, userById } = useSelector((state) => state.profileGet);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(profile(id));
  }, [id]);

  const profle = userById?.data?.user[0];
  return (
    user !== null && (
      <Sidebar>
        <div className="main__data">
          <Header title="Профиль" subtitle="Добро пожаловать в VINZ" />
          {Object.keys(user).length !== 0 && (
            <div className="row  detail">
              <div className="row__col-6">
                <div class="row__item">
                  <div class="row__title-14">Личная информация</div>
                  <div class="list-key-val">
                    <div class="list-key-val__item">
                      <span>Фамилия:</span>
                      <span>{profle?.last_name}</span>
                    </div>
                    <div class="list-key-val__item">
                      <span>Имя:</span>
                      <span>{profle?.name}</span>
                    </div>
                    <div class="list-key-val__item">
                      <span>Отчество:</span>
                      <span>{profle?.middle_name}</span>
                    </div>
                  </div>
                </div>
                <div class="row__item">
                  <div class="row__title-14">Контакты</div>
                  <div class="list-key-val">
                    <div class="list-key-val__item">
                      <span>Телефон:</span>
                      <span>{profle?.phone}</span>
                    </div>
                    {profle?.additional_phone.map((item) => {
                      return (
                        <div class="list-key-val__item">
                          <span>Доп. телефон:</span>
                          <span>{item}</span>
                        </div>
                      );
                    })}

                    <div class="list-key-val__item">
                      <span>E-Mail:</span>
                      <span>{profle?.email}</span>
                    </div>
                    {profle?.additional_email.map((item) => {
                      return (
                        <div class="list-key-val__item">
                          <span>Доп. E-Mail:</span>
                          <span>{item}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div class="row__item">
                  <div class="row__title-14">Статус</div>
                  <div class="list-key-val">
                    <div class="list-key-val__item">
                      <span>Наличие PRO:</span>
                      {/* <span>2382</span> */}
                    </div>
                    <div class="list-key-val__item">
                      <span>Доступ к аукциона:</span>
                      {/* <span>2012</span> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row__col-6">
                <div class="row__item">
                  <div class="row__title-14">Юр. информация</div>
                  <div class="list-key-val">
                    <div class="list-key-val__item">
                      <span>Наименование юр.лица:</span>
                      <span>2382</span>
                    </div>
                    <div class="list-key-val__item">
                      <span>Юр. адрес:</span>
                      <span>2012</span>
                    </div>
                    <div class="list-key-val__item">
                      <span>Телефон:</span>
                      <span>11111 км</span>
                    </div>
                    <div class="list-key-val__item">
                      <span>E-Mail:</span>
                      <span>11111 км</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </Sidebar>
    )
  );
}

export default Profile;
