export const colorCatAuto = (color) => {
  switch (color) {
    case "Новое":
      return "tag-success";
      break;
    case "С пробегом":
      return "tag-warning";
      break;
    case "Поврежденное":
      return "tag-danger";
      break;
  }
};
