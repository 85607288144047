import axios from "axios";

const API_URL = "https://tg.kuleshov.studio/api/v1/marks";

const axiosWithNewToken = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
  withCredentials: true,
});

// Добавляем интерсептор для нового экземпляра axios
axiosWithNewToken.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer 162|sK53DHRsqJOOAqKmfb69OPAnUKqeJOZ8HDyoIbZdf57db074`;
  return config;
});
// Application All LiST

//
const marksList = async (query) => {
  const response = await axiosWithNewToken.get(API_URL, {
    params: query,
  });
  return response.data;
};

//

const markService = {
  marksList,
};

export default markService;
