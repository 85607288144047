import React, { useRef, useState } from "react";

function LotsPrice({ item, setShow, setPrice, price, remainingTime }) {
  const inputRef = useRef(null);
  const [error, setError] = useState("");

  const formatPrice = (value) => {
    // Удаляем любые нецифровые символы
    const numericValue = value.replace(/\D/g, "");

    // Возвращаем отформатированную строку с разделением тысяч через пробелы
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const onInputChange = (e) => {
    const inputValue = e.target.value;

    // Убираем любой символ кроме цифр
    let formattedValue = formatPrice(inputValue);

    // Проверяем, чтобы число не начиналось с нуля, если оно больше одной цифры
    if (formattedValue.startsWith("0") && formattedValue.length > 1) {
      formattedValue = formattedValue.slice(1);
    }

    // Обновляем значение инпута
    inputRef.current.value = formattedValue;
  };

  const onSubmitPrice = (e) => {
    e.preventDefault();

    // Проверяем, истекло ли время аукциона
    if (remainingTime === "0д 0ч 0м") {
      setError("Аукцион завершен. Ставку делать нельзя.");
      return;
    }

    const inputValue = inputRef.current.value.replace(/\s/g, ""); // Убираем пробелы перед преобразованием в число

    if (!inputValue || Number(inputValue) === 0) {
      setError("Ставка должна быть больше 0");
      return;
    }

    if (item.min_rate && Number(inputValue) < item.min_rate) {
      setError(`Ставка должна быть не менее ${item.min_rate} ₽`);
      return;
    }

    setError("");
    setShow(true);
    setPrice({ ...price, price: Number(inputValue), greadeId: item.id });
  };

  return (
    <form onSubmit={onSubmitPrice}>
      <input
        ref={inputRef}
        type="text" // Меняем на text, чтобы контролировать ввод
        className="input-style price-input card__item-inputprice"
        placeholder="Ваша ставка"
        name="price"
        min={0}
        required
        onChange={onInputChange}
        autoComplete="off"
        disabled={remainingTime === "0д 0ч 0м"} // Блокируем инпут, если время истекло
      />
      <button
        type="submit"
        className="gradient-btn-blue w-100"
        disabled={remainingTime === "0д 0ч 0м"}
      >
        Сделать ставку
      </button>
      {item.min_rate && (
        <div className="lots-price__min__rate d-flex justify-content-between">
          <div>Минимальная ставка:</div>
          <div>{item.min_rate} ₽</div>
        </div>
      )}
      {remainingTime === "0д 0ч 0м" && (
        <div className="error-rate-message">Время вышло ставка невозможно </div>
      )}
      {error && <div className="error-rate-message">{error}</div>}
    </form>
  );
}

export default LotsPrice;
