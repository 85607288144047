import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationService from "../applicationsService";

const initialState = {
  result: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Profile get

export const applicationsCheckInStock = createAsyncThunk(
  "applications/checkInStock",
  async (id, thunkAPI) => {
    try {
      return await applicationService.applicationsCheck(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const applicationsCheckInStockSlice = createSlice({
  name: "applicationsCheckInStock",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applicationsCheckInStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsCheckInStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.result = action.payload;
      })
      .addCase(applicationsCheckInStock.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.result = null;
      });
  },
});

export default applicationsCheckInStockSlice.reducer;
