const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: state.isFocused ? "20px 20px 0 0" : "20px",
    border: state.isFocused ? "none" : "1px solid #E0E0E0", // Add border to the control element
    boxShadow: state.isFocused ? "none" : "1px solid #E0E0E0", // Add border to the control element
    "&:focus": {
      border: "none",
      boxShadow: "none",
    },
    "&:focus-visible": {
      border: "none",
      boxShadow: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: "500",
    color: state.isSelected ? "#190D2E" : "#8D8796",
    backgroundColor: "transparent",
    zIndex: 999,
    "&:hover": {
      color: "#190D2E", // Change background color on hover
      cursor: "pointer", // Change cursor to pointer on hover
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#190D2E",
    fontSize: "14px",
    padding: "8px ",
    zIndex: 999,
  }),

  menuPortal: (base) => ({ ...base, zIndex: 9999, borderRadius: "20px" }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    marginTop: "0px",
    borderRadius: "0 0 20px 20px",
  }),
};

export default selectStyle;
