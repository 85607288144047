import { createSlice } from "@reduxjs/toolkit";

const headerAdaptiveSlice = createSlice({
  name: "header/adaptive",
  initialState: false,
  reducers: {
    setHeaderAdaptiveSlice: (state, action) => {
      return action.payload;
    },
  },
});

export const { setHeaderAdaptiveSlice } = headerAdaptiveSlice.actions;
export default headerAdaptiveSlice.reducer;
