import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileService from "../profileService";

const initialState = {
  user: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Profile post

export const profileUpdate = createAsyncThunk(
  "profile/update",
  async (data, thunkAPI) => {
    try {
      return await profileService.profileUpdate(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const profileUpdateSlice = createSlice({
  name: "profileUpdate  ",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(profileUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(profileUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(profileUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      });
  },
});

export default profileUpdateSlice.reducer;
