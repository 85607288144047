import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import imageService from "../TokenForImageService";

const initialState = {
  token: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Profile post

export const imageTokenData = createAsyncThunk(
  "image/get",
  async (thunkAPI) => {
    try {
      return await imageService.imageToken();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const imageTokenSlice = createSlice({
  name: "imageTokenSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(imageTokenData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(imageTokenData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = action.payload;
      })
      .addCase(imageTokenData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.token = null;
      });
  },
});

export default imageTokenSlice.reducer;
