import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import filterService from "../filterService";

const initialState = {
  filter: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const filterIndex = createAsyncThunk(
  "filter/index",
  async (query, thunkAPI) => {
    try {
      return await filterService.filterIndex(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const filterIndexSlice = createSlice({
  name: "filterIndexSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(filterIndex.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(filterIndex.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.filter = action.payload;
      })
      .addCase(filterIndex.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.filter = null;
      });
  },
});

export default filterIndexSlice.reducer;
