import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import markService from "../markService";

const initialState = {
  marks: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// City get

export const marksIndex = createAsyncThunk(
  "marks/get",
  async (query, thunkAPI) => {
    try {
      return await markService.marksList(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const marksIndexSlice = createSlice({
  name: "marksIndex",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(marksIndex.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(marksIndex.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.marks = action.payload;
      })
      .addCase(marksIndex.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.marks = null;
      });
  },
});

export default marksIndexSlice.reducer;
