import React from "react";

function RotateLeftIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.50016 8.16626C1.72117 8.16626 1.93314 8.25406 2.08942 8.41034C2.2457 8.56662 2.33349 8.77858 2.33349 8.99959C2.32357 10.5604 2.85825 12.0759 3.84545 13.285C4.83264 14.494 6.21055 15.321 7.74186 15.6234C9.27317 15.9259 10.862 15.6849 12.2347 14.9419C13.6075 14.199 14.6782 13.0006 15.2624 11.5531C15.8466 10.1057 15.9078 8.49986 15.4355 7.01216C14.9631 5.52446 13.9868 4.24804 12.6746 3.40274C11.3624 2.55745 9.7965 2.19619 8.24661 2.38119C6.69672 2.56618 5.25986 3.28586 4.18349 4.41626H6.18349C6.40451 4.41626 6.61647 4.50406 6.77275 4.66034C6.92903 4.81662 7.01683 5.02858 7.01683 5.24959C7.01683 5.47061 6.92903 5.68257 6.77275 5.83885C6.61647 5.99513 6.40451 6.08293 6.18349 6.08293H2.40849C2.18748 6.08293 1.97552 5.99513 1.81924 5.83885C1.66296 5.68257 1.57516 5.47061 1.57516 5.24959V1.49959C1.57516 1.27858 1.66296 1.06662 1.81924 0.910337C1.97552 0.754057 2.18748 0.66626 2.40849 0.66626C2.62951 0.66626 2.84147 0.754057 2.99775 0.910337C3.15403 1.06662 3.24183 1.27858 3.24183 1.49959V2.97459C4.6296 1.64797 6.43287 0.84122 8.34681 0.6907C10.2608 0.540181 12.168 1.05513 13.7461 2.1485C15.3242 3.24187 16.4763 4.8466 17.0078 6.69142C17.5393 8.53624 17.4175 10.508 16.663 12.2734C15.9085 14.0387 14.5676 15.4895 12.8669 16.3803C11.1663 17.2711 9.21016 17.5474 7.32929 17.1625C5.44843 16.7776 3.75816 15.755 2.54422 14.2677C1.33027 12.7803 0.667105 10.9194 0.666826 8.99959C0.666826 8.77858 0.754623 8.56662 0.910904 8.41034C1.06718 8.25406 1.27915 8.16626 1.50016 8.16626Z"
        fill="#190D2E"
      />
    </svg>
  );
}

export default RotateLeftIcon;
