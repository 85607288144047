import React from "react";

function RotateRightIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4998 8.16626C16.2788 8.16626 16.0669 8.25406 15.9106 8.41034C15.7543 8.56662 15.6665 8.77858 15.6665 8.99959C15.6764 10.5604 15.1417 12.0759 14.1546 13.285C13.1674 14.494 11.7894 15.321 10.2581 15.6234C8.72683 15.9259 7.13799 15.6849 5.76526 14.9419C4.39253 14.199 3.32184 13.0006 2.73761 11.5531C2.15337 10.1057 2.09217 8.49986 2.56452 7.01216C3.03688 5.52446 4.01323 4.24804 5.32542 3.40274C6.63762 2.55745 8.2035 2.19619 9.75339 2.38119C11.3033 2.56618 12.7401 3.28586 13.8165 4.41626H11.8165C11.5955 4.41626 11.3835 4.50406 11.2273 4.66034C11.071 4.81662 10.9832 5.02858 10.9832 5.24959C10.9832 5.47061 11.071 5.68257 11.2273 5.83885C11.3835 5.99513 11.5955 6.08293 11.8165 6.08293H15.5915C15.8125 6.08293 16.0245 5.99513 16.1808 5.83885C16.337 5.68257 16.4248 5.47061 16.4248 5.24959V1.49959C16.4248 1.27858 16.337 1.06662 16.1808 0.910337C16.0245 0.754057 15.8125 0.66626 15.5915 0.66626C15.3705 0.66626 15.1585 0.754057 15.0023 0.910337C14.846 1.06662 14.7582 1.27858 14.7582 1.49959V2.97459C13.3704 1.64797 11.5671 0.84122 9.65319 0.6907C7.73924 0.540181 5.83203 1.05513 4.25394 2.1485C2.67585 3.24187 1.52368 4.8466 0.992197 6.69142C0.460713 8.53624 0.582515 10.508 1.33701 12.2734C2.09151 14.0387 3.43242 15.4895 5.13309 16.3803C6.83375 17.2711 8.78984 17.5474 10.6707 17.1625C12.5516 16.7776 14.2418 15.755 15.4558 14.2677C16.6697 12.7803 17.3329 10.9194 17.3332 8.99959C17.3332 8.77858 17.2454 8.56662 17.0891 8.41034C16.9328 8.25406 16.7209 8.16626 16.4998 8.16626Z"
        fill="#190D2E"
      />
    </svg>
  );
}

export default RotateRightIcon;
