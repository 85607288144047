import { useEffect, useRef, useState } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { Link } from "react-router-dom";
import pdf from "../../images/pdf.svg";

const FileUploadFormEdit = ({ setCreateData, createData, report }) => {
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [uploadType, setUploadType] = useState("files"); // Новое состояние для управления типом загрузки
  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    setFiles(selectedFilesArray);

    const fileReaders = selectedFilesArray.map((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      return fileReader;
    });

    Promise.all(fileReaders).then((results) => {
      const previewUrls = results.map((result) => result.result);
      setPreviewUrls((prevUrls) => [...prevUrls, ...previewUrls]);
    });
  };

  useEffect(() => {
    if (uploadType === "files") {
      setCreateData({ ...createData, docs: files });
    }
  }, [files, uploadType]);

  useEffect(() => {
    if (createData.report_url) {
      setUploadType("link");
    } else {
      setUploadType("files");
    }
  }, []);

  const handleUploadTypeChange = (event) => {
    const { value } = event.target;
    setUploadType(value);
    setFiles([]);
    setPreviewUrls([]);
  };
  return (
    <>
      <h5>Отчёт по VIN</h5>
      <div>
        <div className="d-flex">
          <div className="c">
            <label className="custom-radio mr-3">
              <input
                type="radio"
                name="uploadType"
                value="files"
                checked={uploadType === "files"}
                onChange={handleUploadTypeChange}
              />
              <span></span>Загрузить отчет
            </label>
            <label className="custom-radio">
              <input
                type="radio"
                name="uploadType"
                value="link"
                checked={uploadType === "link"}
                onChange={handleUploadTypeChange}
              />
              <span></span> Вставить ссылку
            </label>
          </div>
        </div>
        <div className="mt-5">
          {uploadType === "files" && (
            <>
              <input
                type="file"
                multiple
                onChange={handleFileInputChange}
                ref={fileInputRef}
                className="d-none"
                id="reports_file"
              />
              <div className="d-flex">
                <label htmlFor="reports_file" className="add-car-data__add-btn">
                  <span>Загрузить фото</span>
                </label>
                {files.length === 0 ? (
                  <Link to={report} className="report-file">
                    <img src={pdf} alt="Preview" />
                  </Link>
                ) : (
                  <Gallery>
                    {files.map((file, index) => (
                      <Item
                        original={previewUrls[index]}
                        thumbnail={previewUrls[index]}
                        width="1024"
                        height="768"
                        key={file.name}
                      >
                        {({ ref, open }) => (
                          <div className="report-file">
                            <img
                              ref={ref}
                              onClick={open}
                              src={pdf}
                              alt="Preview"
                            />
                          </div>
                        )}
                      </Item>
                    ))}
                  </Gallery>
                )}
              </div>
            </>
          )}
          {uploadType === "link" && (
            <input
              type="text"
              onChange={(e) =>
                setCreateData({ ...createData, report_url: e.target.value })
              }
              defaultValue={createData.report_url}
              placeholder="Вставьте ссылку "
              className="form-control"
              name="report_url"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FileUploadFormEdit;
