import React, { useState } from "react";
import Sidebar from "../../../components/sidebar/sidebar";

import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AutoInfo from "../../../components/create-edit-application/auto-info/auto_info";
import AutoParameters from "../../../components/create-edit-application/auto-parameters/auto-parameters";
import SellerRepeatModal from "../../../components/seller/seller-repeat-modal/seller-repeat-modal";
import { orderImageEditSlice } from "../../../store/image/image-order-edit/uploadImageEdit";
import { lotsEdit } from "../../../store/seller/lotsEditSlice/lotsEditSlice";
import { lotsRepeat } from "../../../store/seller/lotsRepeatSlice/lotsRepeatSlice";

function SellerAutoRepeat() {
  const [key, setKey] = useState("seller-tab-1");
  const { id } = useParams();
  const handleSelect = (k) => {
    setKey(k);
  };
  const [isTab1Disabled, setIsTab1Disabled] = useState(false);
  const [isTab2Disabled, setIsTab2Disabled] = useState(false);
  const [attachmentOrder, setAttachmentOrder] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { token } = useSelector((state) => state.imageToken);
  const { editInfo } = useSelector((state) => state.lotsEdit);
  const userLocal = JSON.parse(localStorage.getItem("web_vinz"));

  if (
    localStorage.getItem("attachment_token") === null ||
    localStorage.getItem("attachment_token") === "undefined"
  ) {
    localStorage.setItem("attachment_token", token?.data);
  }

  const [isOtherButtonDisabled, setIsOtherButtonDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [createData, setCreateData] = useState({});
  const result = useSelector((state) => state.lotsUpdate);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lotsEdit(id)).then((res) => {
      if (res.payload.status === 200) {
        const fetchedData = { ...res.payload.data.greade };

        fetchedData.end_auction = null;
        fetchedData.binding_offer = null;

        setCreateData(fetchedData);
      }
    });
  }, [id]);

  // Валидация для кнопки перехода на второй таб
  useEffect(() => {
    // Валидация первого таба
    const tab1Inputs =
      createData.vin &&
      createData.cat_auto &&
      createData.end_auction &&
      createData.binding_offer &&
      createData.region_id;
    setIsOtherButtonDisabled(!tab1Inputs);
    //

    // Валидация второго таба
    const tab2Inputs = createData.car_type_id && createData.car_mark_id;
    setIsTab2Disabled(tab2Inputs);
    //
    // Валидация кнопок для создание лота
    const bothTabsInputs = tab1Inputs && tab2Inputs;
    setIsSubmitDisabled(bothTabsInputs);
    //
  }, [createData]);
  //
  const renderContent = () => {
    switch (key) {
      case "seller-tab-1":
        return (
          <AutoParameters
            setKey={setKey}
            setCreateData={setCreateData}
            createData={createData}
            isTab1Disabled={isTab1Disabled}
            setIsTab1Disabled={setIsTab1Disabled}
            setIsOtherButtonDisabled={setIsOtherButtonDisabled}
            keyTab={key}
            editId={id}
          />
        );
      case "seller-tab-2":
        return (
          <AutoInfo
            setCreateData={setCreateData}
            createData={createData}
            keyTab={key}
            isTab2Disabled={isTab2Disabled}
            editId={id}
            setAttachmentOrder={setAttachmentOrder}
            image={editInfo.data.greade.images}
          />
        );
      default:
        return null;
    }
  };

  const onSubmitUpdate = (e) => {
    e.preventDefault();

    if (Array.isArray(createData?.docs) && createData.docs.length > 0) {
      if (!(createData.docs[0] instanceof File)) {
        delete createData.docs;
      }
    } else {
    }

    Object.keys(attachmentOrder).length !== 0
      ? dispatch(orderImageEditSlice(attachmentOrder)).then((res) => {
          if (res.payload.status === 200) {
            dispatch(lotsRepeat({ data: createData, id: id })).then((res) => {
              if (res.payload.status === 200) {
                toast.success("Повторно размещено");
                localStorage.removeItem("attachment_token");
                if (userLocal.role === "Seller") {
                  window.location.replace("/my-auto");
                } else {
                  window.location.replace("/admin/lots/1");
                }
              } else {
                toast.error("Произошла ошибка");
              }
            });
          }
        })
      : dispatch(lotsRepeat({ data: createData, id: id })).then((res) => {
          if (res.payload.status === 200) {
            toast.success("Повторно размещено");
            localStorage.removeItem("attachment_token");
            if (userLocal.role === "Seller") {
              window.location.replace("/my-auto");
            } else {
              window.location.replace("/admin/lots/1");
            }
          } else {
            toast.error("Произошла ошибка");
          }
        });
  };

  return (
    <Sidebar>
      <form className="main__data">
        <div className="main__data-head">
          <div className="main__data-info">
            <div className="main__data-heder-title">
              Разместить автомобиль повторно
            </div>
            <div className="main__data-heder-subtitle">
              Укажите информацию об автомобиле и параметры лота, чтобы запустить
              аукцион
            </div>
          </div>
          {result && result?.isLoading ? (
            <div className="ml-auto blue-outline-btn w-25">
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Идет Процесс...</span>
            </div>
          ) : (
            <div
              className={` ml-auto gradient-btn-blue cursor-pointer ${
                isSubmitDisabled ? "" : "disablet-gradinet-btn"
              }`}
              onClick={(e) => {
                setCreateData({ ...createData, status_id: 1 });
                setShow(!show);
              }}
            >
              {result && result?.isLoading ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Лот повторно размещается...</span>
                </>
              ) : (
                "Разместить повторно"
              )}
            </div>
          )}
        </div>
        <div className="add-car">
          <Tabs activeKey={key} onSelect={handleSelect}>
            <Tab eventKey="seller-tab-1" title="Параметры лота" />
            <Tab
              eventKey="seller-tab-2"
              title="Информация об автомобиле"
              disabled={isOtherButtonDisabled}
            />
          </Tabs>
          <div className="tab-content">{renderContent()}</div>
        </div>
      </form>
      {show && (
        <SellerRepeatModal
          show={show}
          handleClose={handleClose}
          onSubmitUpdate={onSubmitUpdate}
          setCreateData={setCreateData}
          createData={createData}
        />
      )}
    </Sidebar>
  );
}

export default SellerAutoRepeat;
