import axios from "axios";

const API_URL = "https://tg.kuleshov.studio/api/v1";

const axiosWithNewToken = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
  withCredentials: true,
});

// Добавляем интерсептор для нового экземпляра axios
axiosWithNewToken.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer 162|sK53DHRsqJOOAqKmfb69OPAnUKqeJOZ8HDyoIbZdf57db074`;
  return config;
});
// Application All LiST

const appType = async () => {
  const response = await axiosWithNewToken.get(API_URL + "/types");
  return response.data;
};

const appBrand = async (id) => {
  const response = await axiosWithNewToken.get(
    API_URL + "/types/" + id + "/marks"
  );
  return response.data;
};

const appModel = async (id) => {
  const response = await axiosWithNewToken.get(
    API_URL + "/marks/" + id + "/models"
  );
  return response.data;
};

const appYear = async (id) => {
  const response = await axiosWithNewToken.get(
    API_URL + "/models/" + id + "/years"
  );
  return response.data;
};

const appGenerations = async (modelId, yearId) => {
  const response = await axiosWithNewToken.get(
    API_URL + "/models/" + modelId + "/years/" + yearId + "/generations"
  );
  if (response.data.length === 0) {
    response.data = 0;
  }
  return response.data;
};

const appSeries = async (generetionId, modelId) => {
  const response =
    generetionId !== 0
      ? await axiosWithNewToken.get(
          API_URL + "/generations/" + generetionId + "/series"
        )
      : await axiosWithNewToken.get(API_URL + "/models/" + modelId + "/series");
  return response.data;
};

const appModification = async (modelId, seriesId, yearId) => {
  const response = await axiosWithNewToken.get(
    API_URL + "/model/" + modelId + "/series/" + seriesId + "/" + yearId
  );
  return response.data;
};

const appEngines = async (id) => {
  const response = await axiosWithNewToken.get(
    API_URL + "/modifications/" + id + "/engines"
  );
  return response.data;
};

const appTransmissions = async (modificationId) => {
  const response = await axiosWithNewToken.get(
    API_URL + "/modifications/" + modificationId + "/transmissions"
  );
  return response.data;
};

const appGears = async (modificationId) => {
  const response = await axiosWithNewToken.get(
    API_URL + "/modifications/" + modificationId + "/gears"
  );
  return response.data;
};

const applicationTypeService = {
  appType,
  appBrand,
  appModel,
  appYear,
  appGenerations,
  appSeries,
  appModification,
  appEngines,
  appTransmissions,
  appGears,
};

export default applicationTypeService;
