import axios from "axios";

const API_URL = "/v7/applications";

// Application List
const applicationsGet = async (query) => {
  const response = await axios.get(API_URL, {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

// Application Single
const applicationsSingle = async (id) => {
  const response = await axios.get(API_URL + "/" + id);
  return { data: response.data, status: response.status };
};
//

// Application Single
const applicationsCheck = async (id) => {
  const response = await axios.get("/v1/applications/check-availability/" + id);
  return { data: response.data, status: response.status };
};
//

// Garage List
const applicationsGarage = async (query) => {
  const response = await axios.get(API_URL + "/garage", {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

// Ready to buy
const applicationsReadyToBuy = async (id) => {
  const response = await axios.get(API_URL + "/ready-to-buy/" + id);
  return { data: response.data, status: response.status };
};
//

// Application List
const applicationsGuest = async (query) => {
  const response = await axios.get("/v3/applications-guest", {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

// Application List
const applicationsGuestSingle = async (id) => {
  const response = await axios.post("/v1/applications-guest" + "/" + id);
  return { data: response.data, status: response.status };
};
//

const applicationService = {
  applicationsGet,
  applicationsSingle,
  applicationsCheck,
  applicationsGarage,
  applicationsReadyToBuy,
  applicationsGuest,
  applicationsGuestSingle,
};

export default applicationService;
