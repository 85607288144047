import React from "react";
import { Link } from "react-router-dom";

function DraftButton({ item }) {
  return (
    item.status_id === 4 && (
      <Link to={`/edit-auto/${item.id}`}>
        <span className="blue-gradient-text ms-4">Продолжить публикацию</span>
      </Link>
    )
  );
}

export default DraftButton;
