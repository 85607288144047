import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import logo_black from "../../../../images/logo_black.svg";
import { applicationsSingle } from "../../../../store/applications/applicationsSingleSlice/applicationsSingleSlice";

function AdminQrCode() {
  const dispatch = useDispatch();
  const { single } = useSelector((state) => state.applicationsSingle);

  const { id } = useParams();
  useEffect(() => {
    dispatch(applicationsSingle(id));
  }, [id]);

  return (
    <div className="container-lg mt-4">
      <div className="application__qrcode">
        <div className="text-center w-100 d-flex justify-content-between">
          <img src={logo_black} alt="" width="350px" className="d-block" />
          <div>
            <QRCodeCanvas
              value={`https://vinz.ru/applications/single?id=${btoa(id)}`}
              size={300}
            />
          </div>
        </div>
        {single?.data?.title !== "" ? (
          <h1 className="text-center">{single?.data?.title}</h1>
        ) : (
          <h1 className="text-center">{single?.data?.title}</h1>
        )}

        {single?.data?.characteristics?.modifications_engines.engines !==
          "" && (
          <span className="qr-desc">
            <div>Двигатель</div>
            <div>
              {single?.data?.characteristics.modifications_engines.engines}
            </div>
          </span>
        )}

        {single?.data?.characteristics?.transmissions.value !== "" && (
          <span className="qr-desc">
            <div>КПП</div>
            <div>{single?.data?.characteristics.transmissions.value}</div>
          </span>
        )}

        {single?.data?.characteristics?.gears.value !== "" && (
          <span className="qr-desc">
            <div>Привод</div>
            <div>{single?.data?.characteristics.gears.value}</div>
          </span>
        )}

        {single?.data?.characteristics?.milage.value !== "" && (
          <span className="qr-desc">
            <div>Пробег</div>
            <div>{single?.data?.characteristics.milage.value}</div>
          </span>
        )}

        <div className=" text-center application__qrcode--top">
          <span className="application__qrcode--text">
            Возможна покупка в кредит по низкой ставке
          </span>
          <span className="application__qrcode--id">
            {" "}
            ID: {single?.data?.id}
          </span>
        </div>
      </div>
    </div>
  );
}

export default AdminQrCode;
