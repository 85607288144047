import axios from "axios";

const API_URL = "/v1/";

// notifications
const notificationsList = async () => {
  const response = await axios.get(API_URL + 'notify');
  return { data: response.data, status: response.status };
};
//

// seen
const seenNotification = async (id) => {
  const response = await axios.get(`${API_URL}seen/${id}`);
  return { data: response.data, status: response.status };
};
//

const notificationsService = {
  notificationsList,
  seenNotification,
};

export default notificationsService;
