import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function LogsList() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { logs } = useSelector((state) => state.logsList);

  return (
    <>
      <h2>Логи</h2>
      {Object.keys(logs).length !== 0 && logs.data.logs.length !== 0
        ? logs.data.logs.map((item) => {
            return (
              <div className="d-flex justify-content-between align-items-end logs">
                <div>
                  <div className="logs-time">
                    {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                  <div>{item.proccess}</div>
                </div>
                <div>{item.manager}</div>
              </div>
            );
          })
        : "Логи отсутствуют"}
    </>
  );
}

export default LogsList;
