import { createSlice } from "@reduxjs/toolkit";

const filterQuerySlice = createSlice({
  name: "filter/query",
  initialState: false,
  reducers: {
    setfilterQuerySlice: (state, action) => {
      return action.payload;
    },
  },
});

export const { setfilterQuerySlice } = filterQuerySlice.actions;
export default filterQuerySlice.reducer;
