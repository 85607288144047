import React, { useEffect, useState } from "react";

function Timer({ seconds }) {
  const initialTime = seconds; // 3 minutes in seconds
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        clearInterval(timer);
        // Timer has reached 0, you can trigger an action here.
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timer);
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      {time > 0 ? (
        <>Повторный смс через {formatTime(time)}</>
      ) : (
        <>Повторный смс</>
      )}
    </>
  );
}

export default Timer;
