import React, { useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { applicationsAdminEdit } from "../../store/admin/application/applicationAdminEditSlice/applicationAdminEditSlice";
import { imageDelete } from "../../store/image/image-temperaty/imageTemperarySlice";
import { uploadImageEditSlice } from "../../store/image/image-upload-edit/uploadImageEdit";
import { lotsEdit } from "../../store/seller/lotsEditSlice/lotsEditSlice";
import DeleteIcon from "../icons/delete";
import EditIcon from "../icons/edit";
import UploadIcon from "../icons/upload";
import ImageEditorModal from "../image-editor/image-editor";

const ImageUploaderEdit = ({ market, oldimage, setAttachmentOrder }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isDraggingDisabled, setIsDraggingDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [singleImage, setSingleImage] = useState();
  const closeModal = () => setIsModalOpen(false);
  console.log(oldimage);

  const [sortedList, setSortedList] = useState(
    [...oldimage].sort((a, b) => a.order - b.order)
  );

  const reorderList = (sourceIndex, destinationIndex, item) => {
    if (destinationIndex < 0 || destinationIndex >= sortedList.length) return;
    const newList = [...sortedList];
    const [removed] = newList.splice(sourceIndex, 1);
    newList.splice(destinationIndex, 0, removed);
    setSortedList(newList);
    const newListAttachment = newList.map((item) => item.id);

    setAttachmentOrder({
      id: item.id,
      data: {
        orders: newListAttachment,
      },
    });
  };

  const handleFileSelect = async (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const imagesToUpload = Array.from(files).map((file) => ({
        id: `temp_${file.name}`, // временный ID для прелоудера
        url: URL.createObjectURL(file), // предварительный просмотр
        isLoading: true, // начальный статус - загружается
      }));

      // Добавляем все изображения в список с прелоудерами
      setSortedList((prevImages) => [...prevImages, ...imagesToUpload]);

      for (const file of Array.from(files)) {
        const newImage = imagesToUpload.find(
          (img) => img.id === `temp_${file.name}`
        );
        try {
          const base64Data = await fileToBase64(file);

          const res = await dispatch(
            uploadImageEditSlice({
              id: id,
              data: {
                file: base64Data,
                name: file.name,
                preview: file.name,
                type: market ? true : false,
              },
            })
          );

          // Если загрузка успешна, обновляем объект с реальным id и статусом isLoading: false
          if (res.payload.status === 200) {
            setSortedList((prevImages) =>
              prevImages.map((img) =>
                img.id === newImage.id
                  ? {
                      id: res.payload.data.attachments,
                      url: res.payload.data.url,
                      name: res.payload.data.name,
                      isLoading: false,
                    }
                  : img
              )
            );

            market
              ? await dispatch(applicationsAdminEdit(id))
              : await dispatch(lotsEdit(id));
          }

          await pause(500); // Пауза после загрузки каждого изображения
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    }
  };

  // Функция для паузы
  const pause = (duration) => {
    return new Promise((resolve) => setTimeout(resolve, duration));
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <div className="inner-item-title">Фотографии</div>
      <div>
        <label className="add-car-data__add-img w-100">
          <input type="file" multiple hidden onChange={handleFileSelect} />
          <UploadIcon />
          <span>Загрузить фото</span>
        </label>
      </div>

      <div
        className="image-upload__component"
        style={{
          pointerEvents: isDraggingDisabled ? "auto" : "auto", // Disable pointer events visually
          opacity: isDraggingDisabled ? 0.5 : 1, // Adjust visual feedback
        }}
      >
        <ListManager
          items={sortedList} // Use sortedList here instead of oldimage
          direction="horizontal"
          maxItems={7}
          render={(item) => (
            <ListElement
              item={item}
              setSortedList={setSortedList}
              setSingleImage={setSingleImage}
              setIsModalOpen={setIsModalOpen}
              market={market}
            />
          )}
          onDragEnd={(sourceIndex, destinationIndex) => {
            reorderList(sourceIndex, destinationIndex, sortedList[sourceIndex]);
          }}
        />

        {isModalOpen && (
          <ImageEditorModal
            image={singleImage}
            onClose={closeModal}
            show={isModalOpen}
            setSortedList={setSortedList}
            setIsDraggingDisabled={setIsDraggingDisabled}
            market={market}
          />
        )}
      </div>
    </>
  );
};

function ListElement({
  item,
  setSortedList,
  setSingleImage,
  setIsModalOpen,
  market,
}) {
  const openModal = () => setIsModalOpen(true);
  const dispatch = useDispatch();
  const { id } = useParams();

  // Helper function to update the loading state
  const setImageLoading = (id, isLoading) => {
    setSortedList((prevImages) =>
      prevImages.map((img) => (img.id === id ? { ...img, isLoading } : img))
    );
  };

  const deleteImg = (e) => {
    e.preventDefault();
    swal({
      text: "Вы уверены, что хотите удалить изображение?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        // Set the image to loading state before deleting
        setImageLoading(item.id, true);

        try {
          const res = await dispatch(imageDelete(item.id));
          if (res.payload.status === 200) {
            const resEdit = market
              ? await dispatch(applicationsAdminEdit(id))
              : await dispatch(lotsEdit(id));
            if (resEdit.payload.status === 200) {
              setSortedList(
                market
                  ? resEdit.payload.data.attachments
                  : resEdit.payload.data.greade.images
              );
              toast.success("Успешно удалено!");
            }
          }
        } catch (error) {
          console.error("Error deleting image:", error);
        } finally {
          // Remove the image from the list after deletion or on error
          setSortedList((prevImages) =>
            prevImages.filter((img) => img.id !== item.id)
          );
        }
      }
    });
  };

  return (
    <>
      <div className="add-car-data__image-cursor d-flex">
        <div
          onClick={() => {
            openModal();
            setSingleImage(item);
          }}
        >
          <EditIcon />
        </div>
        <div onClick={deleteImg}>
          <DeleteIcon />
        </div>
      </div>
      <div className="add-car-data__image">
        {item.isLoading ? (
          <div className="image-loader">
            <div className="spinner"></div>
          </div>
        ) : (
          <img src={item.url} alt="Image" className="image-preview" />
        )}
      </div>
    </>
  );
}

export default ImageUploaderEdit;
