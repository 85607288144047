import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import chatService from "../chatService";

const initialState = {
  communication: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Chat  Get

export const сhatManagerSend = createAsyncThunk("chat/ManagerSend", async ({id, data} , thunkAPI) => {
  try {
    return await chatService.chatsSendManager(id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data) ||
      error.message ||
      error.toSrting();
    return thunkAPI.rejectWithValue(message);
  }
});
//

const сhatManagerSendSlice = createSlice({
  name: "сhatManagerSend",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(сhatManagerSend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(сhatManagerSend.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.communication = action.payload;
      })
      .addCase(сhatManagerSend.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.communication = null;
      });
  },
});

export default сhatManagerSendSlice.reducer;
