import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sellerService from "../sellerService";

const initialState = {
  lots: {},
  count: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const lotsIndex = createAsyncThunk(
  "auction/lotsIndex",
  async (query, thunkAPI) => {
    try {
      return await sellerService.lotsIndex(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const lotsIndexSlice = createSlice({
  name: "lotsIndexSlice",
  initialState,
  reducers: {
    sellerReset: (state) => {
      state.lots = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(lotsIndex.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lotsIndex.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lots = action.payload.data.filter ? state.lots : action.payload;
        state.count = action.payload.data.total;
      })
      .addCase(lotsIndex.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.lots = null;
      });
  },
});

export const { sellerReset } = lotsIndexSlice.actions;

export default lotsIndexSlice.reducer;
