import React from "react";

function SellerStatusButton({ item, setShow, setId, admin }) {
  const renderFunct = () => {
    if (item.status_id == 2 || item.status_id == 3) {
      return (
        <button
          type="submit"
          className="gradient-btn-blue w-100 mt-auto"
          onClick={(e) => {
            setShow(true);
            setId(item.id);
          }}
        >
          Выбрать действие
        </button>
      );
    }
    if (item.status_id == 3 && item.win != null) {
      return (
        <>
          {admin && (
            <div className="mt-auto">
              <span className="green-gradient-text mt-auto auction-status d-block">
                Готов продать
              </span>
              <span className="auction-status__max_rate">
                Менеджер свяжется с вами
              </span>
            </div>
          )}
        </>
      );
    }

    if (item.status_id == 3 && item.win_other) {
      return (
        <div className="mt-auto">
          <span className="red-gradient-text mt-auto auction-status d-block">
            Продано на другом аукционе
          </span>
          <span className="auction-status__max_rate">
            Ставка победителя : {item.win_other_sum} ₽
          </span>
        </div>
      );
    }
    if (item.status_id == 3 && item.win == null && !item.win_other) {
      return (
        <button
          type="submit"
          className="gradient-btn-blue w-100 mt-auto"
          onClick={(e) => {
            setShow(true);
            setId(item.id);
          }}
        >
          Выбрать действие
        </button>
      );
    }
  };

  return renderFunct();
}

export default SellerStatusButton;
