import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Sidebar from "../../../../components/sidebar/sidebar";
import { applicationAdminStore } from "../../../../store/admin/application/applicationAdminStoreSlice/applicationAdminStoreSlice";
import { orderImageEditSlice } from "../../../../store/image/image-order-edit/uploadImageEdit";
import { imageTokenData } from "../../../../store/token-for-image/tokenImageSlice/tokenImageSlice";

import AutoInfo from "../../../../components/create-edit-application/auto-info/auto_info";
import AutoParameters from "../../../../components/create-edit-application/auto-parameters/auto-parameters";

function AdminApplicationCreate() {
  const [key, setKey] = useState("auto-tab-1");
  const handleSelect = (k) => {
    setKey(k);
  };
  const [isTab1Disabled, setIsTab1Disabled] = useState(false);
  const [isTab2Disabled, setIsTab2Disabled] = useState(false);
  const [attachmentOrder, setAttachmentOrder] = useState({});

  const { token } = useSelector((state) => state.imageToken);

  if (
    localStorage.getItem("attachment_token") === null ||
    localStorage.getItem("attachment_token") === "undefined"
  ) {
    localStorage.setItem("attachment_token", token?.data);
  }

  const [isOtherButtonDisabled, setIsOtherButtonDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [createData, setCreateData] = useState({});
  const result = useSelector((state) => state.applicationAdminStore);
  const dispatch = useDispatch();

  useEffect(() => {
    setCreateData({
      ...createData,
      _token: localStorage.getItem("attachment_token"),
    });
  }, [token]);

  useEffect(() => {
    dispatch(imageTokenData());
  }, []);

  // Валидация для кнопки перехода на второй таб
  useEffect(() => {
    // Валидация первого таба
    const tab1Inputs =
      createData.vin &&
      createData.attribute_diagnostics &&
      createData.attribute_transaction &&
      createData.region_id;
    setIsOtherButtonDisabled(!tab1Inputs);
    //
    // Валидация второго таба
    let tab2Inputs = createData.car_type_id && createData.car_mark_id;
    if (createData.car_type_id === 27) tab2Inputs = true;

    setIsTab2Disabled(tab2Inputs);
    //

    // Валидация кнопок для создание лота
    const bothTabsInputs = tab1Inputs && tab2Inputs;
    setIsSubmitDisabled(bothTabsInputs);
    //
  }, [createData]);
  //

  const renderContent = () => {
    switch (key) {
      case "auto-tab-1":
        return (
          <AutoParameters
            setKey={setKey}
            setCreateData={setCreateData}
            createData={createData}
            isTab1Disabled={isTab1Disabled}
            setIsTab1Disabled={setIsTab1Disabled}
            setIsOtherButtonDisabled={setIsOtherButtonDisabled}
            keyTab={key}
            market={true}
          />
        );
      case "auto-tab-2":
        return (
          <AutoInfo
            setCreateData={setCreateData}
            createData={createData}
            keyTab={key}
            isTab2Disabled={isTab2Disabled}
            isSubmitDisabled={isSubmitDisabled}
            setAttachmentOrder={setAttachmentOrder}
            market={true}
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    Object.keys(attachmentOrder).length !== 0
      ? dispatch(orderImageEditSlice(attachmentOrder)).then((res) => {
          dispatch(applicationAdminStore(createData)).then((res) => {
            if (res.payload.status === 200) {
              toast.success("Успешно создан");
              localStorage.removeItem("attachment_token");
              window.location.replace("/admin/application/0");
            } else {
              toast.error("Произошла ошибка");
            }
          });
        })
      : dispatch(applicationAdminStore(createData)).then((res) => {
          if (res.payload.status === 200) {
            toast.success("Успешно создан");
            localStorage.removeItem("attachment_token");
            window.location.replace("/admin/application/0");
          } else {
            toast.error("Произошла ошибка");
          }
        });
  };

  return (
    <Sidebar>
      <form onSubmit={onSubmit} className="main__data">
        <div className="main__data-head">
          <div className="main__data-info">
            <div className="main__data-heder-title">
              Добавление автомобиля в маркет
            </div>
          </div>
          {result && result?.isLoading ? (
            <div className="ml-auto blue-outline-btn w-25">
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Идет Процесс...</span>
            </div>
          ) : (
            <>
              <button
                type="submit"
                className={`blue-outline-btn ml-auto ${
                  isSubmitDisabled ? "" : "disablet"
                } ${result?.isLoading && "disablet"}`}
                onClick={(e) => setCreateData({ ...createData, status_id: 4 })}
              >
                Сохранить в черновик
              </button>
            </>
          )}
        </div>
        <div className="add-car">
          <Tabs activeKey={key} onSelect={handleSelect}>
            <Tab eventKey="auto-tab-1" title="Параметры авто" />
            <Tab
              eventKey="auto-tab-2"
              title="Информация об автомобиле"
              disabled={isOtherButtonDisabled}
            />
          </Tabs>
          <div className="tab-content">{renderContent()}</div>
        </div>
      </form>
    </Sidebar>
  );
}

export default AdminApplicationCreate;
