import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cityService from "../cityService";

const initialState = {
  city: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// City get

export const cityGet = createAsyncThunk("city/get", async (query, thunkAPI) => {
  try {
    return await cityService.cityGet(query);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data) ||
      error.message ||
      error.toSrting();
    return thunkAPI.rejectWithValue(message);
  }
});
//

const cityGetSlice = createSlice({
  name: "cityGet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cityGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cityGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.city = action.payload;
      })
      .addCase(cityGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.city = null;
      });
  },
});

export default cityGetSlice.reducer;
