import axios from "axios";

const API_URL = "/v1/admin/applications/";

// Application store
const applicationsAdminStore = async (data) => {
  const response = await axios.post(API_URL + "create", data);
  return { data: response.data, status: response.status };
};
//

// Application edit
const applicationsAdminEdit = async (id) => {
  const response = await axios.get(API_URL + "edit/" + id);
  return { data: response.data, status: response.status };
};
//

// Application update
const applicationsAdminUpdate = async (id, data) => {
  const response = await axios.post(API_URL + "update/" + id, data);
  return { data: response.data, status: response.status };
};
//

// Application delete
const applicationsAdminDelete = async (id) => {
  const response = await axios.delete(API_URL + id);
  return { data: response.data, status: response.status };
};
//

const applicationAdminService = {
  applicationsAdminStore,
  applicationsAdminDelete,
  applicationsAdminEdit,
  applicationsAdminUpdate,
};

export default applicationAdminService;
