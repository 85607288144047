import React from "react";
import { Link } from "react-router-dom";
import Header from "../header/header";

function Verification404() {
  return (
    <div className="main__data">
      <Header title="Аукцион" />
      <div className="close-auction mt-3">
        <p>
          Чтобы получить доступ к аукциону необходимо пройти верификацию и
          внести депозит
        </p>
        <Link to="/setting" className="gradient-btn-blue">
          Пройти верификацию
        </Link>
        {/* <button type="submit" className="gradient-btn-blue">
          Внести депозит
        </button> */}
      </div>
    </div>
  );
}

export default Verification404;
