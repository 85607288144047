import axios from "axios";

const API_URL = "/v1/lots";

// Lots Create
const lotsCreate = async (data) => {
  const response = await axios.post(API_URL, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return { data: response.data, status: response.status };
};
//

// Lots Update
const lotsUpdate = async (data, id) => {
  const response = await axios.post(API_URL + "/" + id, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return { data: response.data, status: response.status };
};
//

// Lots Index
const lotsIndex = async (query) => {
  const response = await axios.get(API_URL, {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

// Lots Edit
const lotsEdit = async (id) => {
  const response = await axios.get(API_URL + "/" + id + "/edit");
  return { data: response.data, status: response.status };
};
//

// Lots Update
const lotsGetContact = async (id) => {
  const response = await axios.get(API_URL + "/get-contact/" + id);
  return { data: response.data, status: response.status };
};

//

// Lots win other auction
const lotsWinOther = async (id, data) => {
  const response = await axios.post(API_URL + "/win-other/" + id, data);

  return { data: response.data, status: response.status };
};

//

// Lots Repeat
const lotsRepeat = async (data, id) => {
  const response = await axios.post(API_URL + "/repeat/" + id, data);
  return { data: response.data, status: response.status };
};
//

// Lots withDrawn
const lotsWithdrawn = async (id) => {
  const response = await axios.get(API_URL + "/withdrawn/" + id);
  return { data: response.data, status: response.status };
};
//

const sellerService = {
  lotsCreate,
  lotsIndex,
  lotsUpdate,
  lotsEdit,
  lotsGetContact,
  lotsRepeat,
  lotsWithdrawn,
  lotsWinOther,
};

export default sellerService;
