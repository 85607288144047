import React from "react";

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.5001 11.667C17.2791 11.667 17.0671 11.7548 16.9108 11.9111C16.7545 12.0674 16.6667 12.2793 16.6667 12.5003V15.8337C16.6667 16.0547 16.579 16.2666 16.4227 16.4229C16.2664 16.5792 16.0544 16.667 15.8334 16.667H4.16675C3.94573 16.667 3.73377 16.5792 3.57749 16.4229C3.42121 16.2666 3.33341 16.0547 3.33341 15.8337V12.5003C3.33341 12.2793 3.24562 12.0674 3.08934 11.9111C2.93306 11.7548 2.7211 11.667 2.50008 11.667C2.27907 11.667 2.06711 11.7548 1.91083 11.9111C1.75455 12.0674 1.66675 12.2793 1.66675 12.5003V15.8337C1.66675 16.4967 1.93014 17.1326 2.39898 17.6014C2.86782 18.0703 3.50371 18.3337 4.16675 18.3337H15.8334C16.4965 18.3337 17.1323 18.0703 17.6012 17.6014C18.07 17.1326 18.3334 16.4967 18.3334 15.8337V12.5003C18.3334 12.2793 18.2456 12.0674 18.0893 11.9111C17.9331 11.7548 17.7211 11.667 17.5001 11.667ZM9.40841 13.092C9.48767 13.1679 9.58112 13.2273 9.68341 13.267C9.78316 13.3111 9.89102 13.3339 10.0001 13.3339C10.1091 13.3339 10.217 13.3111 10.3167 13.267C10.419 13.2273 10.5125 13.1679 10.5917 13.092L13.9251 9.75866C14.082 9.60174 14.1702 9.38891 14.1702 9.16699C14.1702 8.94507 14.082 8.73224 13.9251 8.57533C13.7682 8.41841 13.5553 8.33025 13.3334 8.33025C13.1115 8.33025 12.8987 8.41841 12.7417 8.57533L10.8334 10.492V2.50033C10.8334 2.27931 10.7456 2.06735 10.5893 1.91107C10.4331 1.75479 10.2211 1.66699 10.0001 1.66699C9.77907 1.66699 9.56711 1.75479 9.41083 1.91107C9.25455 2.06735 9.16675 2.27931 9.16675 2.50033V10.492L7.25841 8.57533C7.18072 8.49763 7.08847 8.43599 6.98696 8.39394C6.88544 8.35189 6.77663 8.33025 6.66675 8.33025C6.55687 8.33025 6.44806 8.35189 6.34654 8.39394C6.24502 8.43599 6.15278 8.49763 6.07508 8.57533C5.99738 8.65302 5.93575 8.74527 5.8937 8.84678C5.85165 8.9483 5.83 9.05711 5.83 9.16699C5.83 9.27687 5.85165 9.38568 5.8937 9.4872C5.93575 9.58872 5.99738 9.68096 6.07508 9.75866L9.40841 13.092Z"
        fill="#8D8796"
      />
    </svg>
  );
}

export default UploadIcon;
