import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonWithBackground from "../../components/button/button-with-background";

function InsufficientRights() {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const goToLogin = () => {
    setIsButtonDisabled(true); // Делаем кнопку неактивной
    navigate("/login");
  };

  return (
    <div className="container insufficient-rights-page">
      <h1>403 | Доступ запрещён</h1>
      <p>У вас недостаточно прав для доступа к этой странице.</p>
      <div className="col-lg-3">
        {isButtonDisabled ? (
          <div className="ml-auto blue-outline-btn w-100">
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Идет Процесс...</span>
          </div>
        ) : (
          <button
            className="button-with-background submit w-100"
            onClick={goToLogin}
          >
            Вернуться к входу
          </button>
        )}
      </div>
    </div>
  );
}

export default InsufficientRights;
