import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/auth/login/loginSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function LogoutComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoadingLogout } = useSelector((state) => state.login);
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout()).then((res) => {
      if (res.payload === 200) {
        navigate("/login");
        toast.success("Вы успешно вышли из приложения!");
      }
    });
  };
  return (
    <>
      {isLoadingLogout ? (
        <div className="ml-auto blue-outline-btn">
          <span
            className="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Выходим...</span>
        </div>
      ) : (
        <div className="logout__btn " onClick={onLogout}>
          Выйти
        </div>
      )}
    </>
  );
}

export default LogoutComponent;
