import moment from "moment";
import React from "react";

function LotsDate({ item }) {
  const formattedDate = moment(item.end_auction).format(
    "D MMMM YYYY [в] HH:mm"
  );

  const bindingOffernDate = moment(item.binding_offer).format(
    "D MMMM YYYY [в] HH:mm"
  );
  let dateBlock = "";

  if (item.status_id === 1) {
    dateBlock = (
      <>
        <span className="ms-4">{formattedDate}</span> Аукцион закончится
      </>
    );
  } else if (item.status_id === 2) {
    dateBlock = (
      <>
        <span className="ms-4">{bindingOffernDate}</span> Срок ОП закончится
      </>
    );
  }
  return <>{dateBlock}</>;
}

export default LotsDate;
