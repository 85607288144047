import React, { useState } from "react";

const SendMessageForm = ({ postChatMessage, isLoading }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    postChatMessage(message);
  };

  return (
    <form onSubmit={handleSubmit} className="send-message-form">
      <div className="chat-footer">
        <input
          type="text"
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Напишите сообщение..."
          className="chat-input"
          value={message}
        />
        {isLoading ? (
          <div className="spinner-border text-info" role="status">
            <span className="sr-only"></span>
          </div>
        ) : (
          message !== "" && (
            <button type="submit" className="send-btn">
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0499 8.00008C16.0506 8.31564 15.9617 8.62491 15.7935 8.89192C15.6254 9.15893 15.3848 9.37272 15.0999 9.50841L2.89161 15.2917C2.66817 15.4136 2.42064 15.4847 2.16661 15.5001C1.88862 15.4995 1.6152 15.4294 1.37122 15.2961C1.12725 15.1629 0.920479 14.9708 0.769737 14.7372C0.618994 14.5036 0.529068 14.2361 0.508146 13.9589C0.487223 13.6817 0.535969 13.4036 0.649944 13.1501L2.54994 8.83341H7.16661C7.38762 8.83341 7.59959 8.74561 7.75587 8.58933C7.91215 8.43305 7.99994 8.22109 7.99994 8.00008C7.99994 7.77906 7.91215 7.5671 7.75587 7.41082C7.59959 7.25454 7.38762 7.16674 7.16661 7.16674H2.54994L0.649944 2.89174C0.512271 2.58108 0.472842 2.23576 0.536943 1.90207C0.601045 1.56837 0.765612 1.26223 1.00859 1.0247C1.25157 0.787157 1.56135 0.629562 1.89641 0.573032C2.23148 0.516502 2.57581 0.563737 2.88328 0.708408L15.0916 6.49174C15.3781 6.62624 15.6203 6.83951 15.79 7.10661C15.9597 7.37372 16.0499 7.68362 16.0499 8.00008Z"
                  fill="white"
                />
              </svg>
            </button>
          )
        )}
      </div>
    </form>
  );
};

export default SendMessageForm;
