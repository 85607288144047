import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Entity from "../../components/entity/entity";
import Header from "../../components/header/header";
import Preloader from "../../components/prelouder/prelouder";
import Sidebar from "../../components/sidebar/sidebar";
import Verification from "../../components/verification/verification";
import { profile } from "../../store/profile/profileGetSlice/profileGetSlice";

function Setting() {
  const { user, isLoading } = useSelector((state) => state.profileGet);
  const [key, setKey] = useState("setting-tab-1");
  const handleSelect = (k) => {
    setKey(k);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profile());
  }, []);
  const renderContent = () => {
    switch (key) {
      case "setting-tab-1":
        return <Verification user={user} />;
      case "setting-tab-2":
        return <Entity />;
      default:
        return null;
    }
  };

  return (
    <Sidebar>
      <div className="main__data">
        <Header title="Настройки" subtitle="Добро пожаловать в VINZ" />
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="settings">
            <Tabs activeKey={key} onSelect={handleSelect}>
              <Tab eventKey="setting-tab-1" title="Личные данные" />
              {/* <Tab eventKey="setting-tab-2" title=" Юр. данные" /> */}
            </Tabs>
            <div className="tab-content">{renderContent()}</div>
          </div>
        )}
      </div>
    </Sidebar>
  );
}

export default Setting;
