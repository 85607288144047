import axios from "axios";

const API_URL = "/v5/profile";

// Profile get
const profileGet = async (id) => {
  const url = id ? API_URL + "/" + id : API_URL;
  const response = await axios.get(url);
  return { data: response.data, status: response.status };
};
//

// Profile get
const profileUpdate = async (data) => {
  const response = await axios.post(API_URL, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return { data: response.data, status: response.status };
};
//

// Profile delete /v5/profile
const profileDeleteAdditional = async (data) => {
  const response = await axios.post(API_URL + "/delete-additional", data);
  return { data: response.data, status: response.status };
};
//

const profileService = {
  profileGet,
  profileUpdate,
  profileDeleteAdditional,
};

export default profileService;
