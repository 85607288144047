import { Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profile } from "../store/profile/profileGetSlice/profileGetSlice";
import { useEffect, useState } from "react";

const ProRoutes = () => {
  const { user } = useSelector((state) => state.profileGet);
  const dispatch = useDispatch();
  const [profileLoaded, setProfileLoaded] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (Object.keys(user).length === 0) {
          await dispatch(profile());
        }
      } finally {
        setProfileLoaded(true);
      }
    };

    fetchProfile();
  }, [dispatch, user]);

  if (!profileLoaded) {
    return null;
  }
  
  const isRole = user?.data?.role === "Pro" || user?.data?.role === "Dealer";

  return user && Object.keys(user).length !== 0 && isRole ? (
    <Outlet />
  ) : (
    <Navigate to="/not-verification" replace={true} />
  );
};

export default ProRoutes;
