import React, { useRef } from "react";
import { verification } from "../../../store/auth/verification/verificationSlice";
import AuthVerification from "../../../components/auth-verification/auth-verification";
import { useSelector } from "react-redux";
import { resetPasswordPost } from "./../../../store/auth/reset-password/resetPasswordSlice";

function ResetPassword() {
  const result = useSelector((state) => state.reserPasswordVerify);
  return (
    <AuthVerification
      verification={resetPasswordPost}
      result={result}
      success_url={"/login"}
      success_text={
        "Пароль успешно сброшен. Пожалуйста, войдите в систему, используя новый пароль."
      }
    />
  );
}

export default ResetPassword;
