import axios from "axios";

const API_URL = "/v1/log";

// log show
const logShow = async (id, query) => {
  const response = await axios.get(API_URL + "/" + id, {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

const logService = {
  logShow,
};

export default logService;
