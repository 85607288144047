import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import reset_img from "../../../images/reset.svg";
import { applicationsGet } from "../../../store/applications/applicationsGetSlice/applicationsGetSlice";
import { setQueryApplicationSlice } from "../../../store/query/applicationQuerySlice";
import { lotsIndex } from "../../../store/seller/lotsIndexSlice/lotsIndexSlice";
import { auto_diagnostics } from "../../../utils/lots-create-array";
import { customNoOptionsMessage } from "../../../utils/option-not-found";
import selectStyle from "../../../utils/select-styles";

function FilterAdminHeader({ user, market }) {
  const isManagerAdmin =
    user?.data?.role === "Admin" || user?.data?.role === "Manager";

  const dispatch = useDispatch();
  const query = useSelector((state) => state.queryApplication);
  const { id } = useParams();
  const [inputValue, setInputValue] = useState("");

  const filterData = (e) => {
    const { name, value } = e.target ? e.target : e;
    const newQuery = { ...query, [name]: value };
    dispatch(setQueryApplicationSlice(newQuery));
    dispatch(market ? applicationsGet(newQuery) : lotsIndex(newQuery));
  };

  const onChecked = () => {
    const newQuery = { ...query, sort: "chat" };
    dispatch(setQueryApplicationSlice(newQuery));
    dispatch(applicationsGet(newQuery));
  };

  const onFilter = (e) => {
    if (e.key === "Enter") {
      filterData(e);
    }
  };

  const onFilterDiagnostic = (e) => filterData(e);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const onReset = () => {
    setInputValue("");

    if (market) {
      dispatch(setQueryApplicationSlice({ status: id }));
      dispatch(applicationsGet({ status: id }));
    } else {
      dispatch(setQueryApplicationSlice({ status: id }));
      dispatch(lotsIndex({ status: id }));
    }
  };

  return (
    <div className="d-flex align-items-center admin__header-filter">
      <div>
        <span>{market ? "Поиск авто" : "Поиск по номеру договора"}</span>
        <input
          type="text"
          className="input-style"
          placeholder="Все"
          name="search"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={onFilter}
        />
      </div>
      {isManagerAdmin && market && (
        <>
          <div className=" mx-4">
            <span>Диагностика</span>
            <Select
              className="input-style"
              noOptionsMessage={customNoOptionsMessage}
              options={auto_diagnostics("filter_diagnostics_web")}
              styles={selectStyle}
              placeholder="Не указано"
              onChange={onFilterDiagnostic}
            />
          </div>
          <div>
            <label className="custom-radio mt-4">
              <input
                type="checkbox"
                name="sort"
                value="chat"
                onChange={onChecked}
              />
              <span></span>Непрочитанные сообщения
            </label>
          </div>
        </>
      )}

      <div className="mt-3 mx-2">
        <button type="button" className="reset-button" onClick={onReset}>
          <img src={reset_img} alt="" />
        </button>
      </div>
    </div>
  );
}

export default FilterAdminHeader;
