import React from "react";

function DeleteIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 3.25H9V2.625C9 2.12772 8.80246 1.65081 8.45083 1.29917C8.09919 0.947544 7.62228 0.75 7.125 0.75H5.875C5.37772 0.75 4.90081 0.947544 4.54917 1.29917C4.19754 1.65081 4 2.12772 4 2.625V3.25H1.5C1.33424 3.25 1.17527 3.31585 1.05806 3.43306C0.940848 3.55027 0.875 3.70924 0.875 3.875C0.875 4.04076 0.940848 4.19973 1.05806 4.31694C1.17527 4.43415 1.33424 4.5 1.5 4.5H2.125V11.375C2.125 11.8723 2.32254 12.3492 2.67417 12.7008C3.02581 13.0525 3.50272 13.25 4 13.25H9C9.49728 13.25 9.97419 13.0525 10.3258 12.7008C10.6775 12.3492 10.875 11.8723 10.875 11.375V4.5H11.5C11.6658 4.5 11.8247 4.43415 11.9419 4.31694C12.0592 4.19973 12.125 4.04076 12.125 3.875C12.125 3.70924 12.0592 3.55027 11.9419 3.43306C11.8247 3.31585 11.6658 3.25 11.5 3.25ZM5.25 2.625C5.25 2.45924 5.31585 2.30027 5.43306 2.18306C5.55027 2.06585 5.70924 2 5.875 2H7.125C7.29076 2 7.44973 2.06585 7.56694 2.18306C7.68415 2.30027 7.75 2.45924 7.75 2.625V3.25H5.25V2.625ZM9.625 11.375C9.625 11.5408 9.55915 11.6997 9.44194 11.8169C9.32473 11.9342 9.16576 12 9 12H4C3.83424 12 3.67527 11.9342 3.55806 11.8169C3.44085 11.6997 3.375 11.5408 3.375 11.375V4.5H9.625V11.375Z"
        fill="url(#paint0_linear_2179_5152)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2179_5152"
          x1="1.76"
          y1="0.75001"
          x2="11.8017"
          y2="1.01312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6C6C" />
          <stop offset="1" stopColor="#F15151" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default DeleteIcon;
