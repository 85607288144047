import axios from "axios";
const API_URL = "/v1/email";
// email get
const emailSend = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

const confirmCode = async (data) => {
  const response = await axios.post(API_URL + "/confirmation", data);
  return { data: response.data, status: response.status };
};

const emailService = {
  emailSend,
  confirmCode,
};

export default emailService;
