import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import emailService from "../emailVerificationService";

const initialState = {
  code: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// sendEmail

export const sendEmail = createAsyncThunk(
  "email/send",
  async (data, thunkAPI) => {
    try {
      return await emailService.emailSend(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const sendEmailSlice = createSlice({
  name: "sendEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.code = action.payload;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.code = null;
      });
  },
});

export default sendEmailSlice.reducer;
