import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AnimatedPage from "../../components/animated/animated";
import AuthForm from "../../components/auth-form/auth-form";
import Timer from "../../components/auth-form/timer/timer";
import ButtonWithBackground from "../../components/button/button-with-background";

function AuthVerification({ verification, result, success_text, success_url }) {
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [password, setPassword] = useState(""); // State for password
  const [confirmPassword, setConfirmPassword] = useState("");
  const [codeError, setCodeError] = useState("");

  const [errorPassword, setErrorPassword] = useState(""); // State for confirmation password

  const user = localStorage.getItem("web_vinz");
  useEffect(() => {
    if (user !== null) {
      navigate("/setting");
    }
  }, [user]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(result?.info).length === 0) {
      navigate("/");
    }
  }, []);

  const handleInputChange = (index, value) => {
    value = value.replace(/[^0-9]/g, "");

    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.setSelectionRange(0, 0);
      inputRefs[index + 1].current.focus();
    }
  };

  const handleInputClick = (index) => {
    inputRefs[index].current.setSelectionRange(0, 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.length < 4) {
      setErrorPassword("Минимум 4 символа.");
    } else if (password !== confirmPassword) {
      setErrorPassword("Пароли не совпали!");
    } else if (inputValues.join("") != result?.info?.data?.code) {
      setCodeError("Неправильно введен код");
      setErrorPassword("");
    } else {
      e.preventDefault();
      setErrorPassword("");
      setCodeError("");
      const data = {
        phone: result?.info?.data?.phone,
        code: inputValues.join(""),
        password: password,
        password_confirmation: confirmPassword,
      };

      dispatch(verification(data))
        .then((response) => {
          if (response.payload.status == 200) {
            toast.success(success_text);
            navigate(success_url);
          } else {
            toast.error("Произошла ошибка!");
          }
        })
        .catch((error) => {
          toast.error("Произошла ошибка!");
        });
    }
  };
  return (
    <AnimatedPage>
      <AuthForm
        title="Подтверждение телефона"
        description="Мы отправили SMS с кодом подтверждения на Ваш номер"
      >
        <form onSubmit={handleSubmit}>
          <div className="position-relative ">
            <div className="d-flex gap-3 mb-3 mt-4">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  className="text-center"
                  value={inputValues[index]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onClick={() => handleInputClick(index)}
                  ref={inputRefs[index]}
                  placeholder="0"
                  required
                />
              ))}
            </div>
            {codeError !== "" && (
              <p className="error-message error-code">{codeError}</p>
            )}
          </div>
          <div className="position-relative mb-3 mt-4">
            <input
              type="password"
              placeholder="Введите пароль"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {errorPassword !== "" && (
              <p className="error-message">{errorPassword}</p>
            )}
          </div>
          <div className="position-relative mb-3 mt-4">
            <input
              type="password"
              placeholder="Подтвердите пароль"
              onChange={(e) => setConfirmPassword(e.target.value)} // Update the confirmation password state
              required
            />
            {errorPassword !== "" && (
              <p className="error-message">{errorPassword}</p>
            )}
          </div>

          <ButtonWithBackground>
            {result?.isLoading ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Идет проверка...</span>
              </>
            ) : (
              "Войти"
            )}
          </ButtonWithBackground>
          <p className="mt-1">
            <Timer seconds={180} />
          </p>
        </form>
      </AuthForm>
    </AnimatedPage>
  );
}

export default AuthVerification;
