import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function FilterChecked({ filter, title, setFilterState, nameInput }) {
  // Создаём состояние для всех чекбоксов
  const [checkboxes, setCheckboxes] = useState({});
  const queryFilter = useSelector((state) => state.filterQuery);
  useEffect(() => {
    if (filter) {
      const initialCheckboxes = {};
      filter.forEach((item) => {
        if (
          queryFilter[nameInput] &&
          queryFilter[nameInput].includes(item.id.toString())
        ) {
          initialCheckboxes[item.id] = true;
        } else {
          initialCheckboxes[item.id] = false;
        }
      });
      setCheckboxes(initialCheckboxes);
    }
  }, [nameInput]);

  // Функция для обработки "Выбрать все"
  const handleSelectAll = () => {
    const areAllChecked = !Object.values(checkboxes).includes(false);
    const newCheckboxes = {};
    const newCheckboxesFilter = areAllChecked
      ? []
      : filter.map((item) => (item.code ? item.code : item.id.toString()));

    Object.keys(checkboxes).forEach((key) => {
      newCheckboxes[key] = !areAllChecked;
    });

    setCheckboxes(newCheckboxes);
    setFilterState((prevState) => ({
      ...prevState,
      [nameInput]: newCheckboxesFilter,
    }));
  };

  // Функция для обработки изменения отдельного чекбокса
  const handleCheckboxChange = (e, item) => {
    const { checked, name } = e.target;
    const valueToUse = item.code ? item.code : item.id.toString(); // Преобразование ID в строку для консистентности

    setCheckboxes((prevCheckboxes) => {
      const newCheckboxes = { ...prevCheckboxes, [item.id]: checked };

      // Обновление filterState с учетом item.code или item.id
      setFilterState((prevState) => ({
        ...prevState,
        [name]: Object.keys(newCheckboxes)
          .filter((key) => newCheckboxes[key])
          .map((key) =>
            filter.find((fItem) => fItem.id.toString() === key).code
              ? filter.find((fItem) => fItem.id.toString() === key).code
              : key
          ), // Используем code, если доступен, иначе ID
      }));

      return newCheckboxes;
    });
  };

  return (
    <div className="main__filter-item">
      <h4>{title}</h4>
      <label className="custom-checkbox">
        <input
          type="checkbox"
          name="select-checkbox"
          className="select-checkbox"
          onClick={handleSelectAll}
          checked={!Object.values(checkboxes).includes(false)}
        />
        <span></span>Выбрать все
      </label>
      <div className="list-radio-btn">
        {filter?.map((item) => (
          <label key={item.id} className="list-checkbox-btn__item">
            <input
              type="checkbox"
              name={nameInput}
              value={item.code ? item.code : item.id}
              checked={checkboxes[item.id]}
              onChange={(e) => handleCheckboxChange(e, item)} // Передаем весь объект item
            />
            <span>{item.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
}

export default FilterChecked;
