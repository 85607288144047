import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import auctionService from "../auctionService";

const initialState = {
  lots: {},
  count: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const bargaining = createAsyncThunk(
  "auction/bargaining",
  async (query, thunkAPI) => {
    try {
      return await auctionService.auctionBargaining(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const bargainingSlice = createSlice({
  name: "bargainingSlice",
  initialState,
  reducers: {
    lotsReset: (state) => {
      state.lots = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bargaining.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(bargaining.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lots = action.payload.data.filter ? state.lots : action.payload;
        state.count = action.payload.data.total;
      })
      .addCase(bargaining.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.lots = null;
      });
  },
});
export const { lotsReset } = bargainingSlice.actions;

export default bargainingSlice.reducer;
