import axios from "axios";

const API_URL = "/v2/application-pro";

// pro Send
const proSend = async () => {
  const response = await axios.get(API_URL);
  return { data: response.data, status: response.status };
};
//


const proService = {
  proSend,
};

export default proService;
