import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { codeSend } from "../../../store/email-verification/codeConfirmSlice/codeConfirmSlice";
import { sendEmail } from "../../../store/email-verification/emailVerificationSlice/emailVerificationSlice";
import { profileDeleteAdditional } from "../../../store/profile/profileDeleteAdditionalSlice/profileDeleteAdditionalSlice";

function ContactDetails({
  user,
  handleChange,
  phoneNumbers,
  setPhoneNumbers,
  emailNumbers,
  setEmailNumbers,
}) {
  const [mail, setMail] = useState({});
  const [active, setActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorСode] = useState("");
  const verificationCode = sessionStorage.getItem("code");

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.emailCode);

  useEffect(() => {
    if (
      !user?.data?.user[0]?.email_confirm &&
      user?.data?.user[0]?.email?.includes("@")
    ) {
      setMail({ mail: user?.data?.user[0]?.email });
      setActive(true);
    }
  }, []);

  // Валидация email
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };
  //

  // Отправка почты
  const onClickEmail = () => {
    if (validateEmail(mail.mail)) {
      setErrorMessage("");
      dispatch(sendEmail(mail)).then((res) => {
        if (res.payload.status === 200) {
          setActive(true);
          sessionStorage.setItem("code", res.payload.data.code);
        } else {
          toast.error("Произошла ошибка , попрообуйте снова!");
        }
      });
    } else {
      setErrorMessage("Введите корректный адрес.");
    }
  };
  //

  // Редактирование почты
  const onClickEditMail = () => {
    setActive(false);
  };
  //

  // Валидация для кода
  const handleCode = (e) => {
    const code = e.target.value;
    // Проверка длины введенного кода
    if (code != verificationCode) {
      setErrorСode("Неверный код");
    } else {
      setErrorСode(""); // Очистить ошибку, если код валиден
    }
  };
  //

  // Отправка кода
  const onClickCode = (e) => {
    dispatch(codeSend({ code: verificationCode, mail: mail.mail })).then(
      (res) => {
        if (res.payload.status === 200) {
          toast.success("Почта успешно сохранена!");
          window.location.replace("/setting ");
        } else {
          toast.error("Произошла ошибка , попрообуйте снова!");
        }
      }
    );
  };
  //
  // Начальное состояние с одним пустым полем для ввода номера телефона

  // Обработчик для добавления нового поля ввода
  const handleAddPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, ""]);
  };
  const handleAddEmailNumber = () => {
    setEmailNumbers([...emailNumbers, ""]);
  };
  // Обработчик для удаления поля ввода
  const handleRemovePhoneNumber = (index, phoneNumber) => {
    const newPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
    dispatch(profileDeleteAdditional({ phone_email: phoneNumber })).then(
      (res) => {
        if (res.payload.status === 200) {
          toast.success("Успешно Удалено!");
          setPhoneNumbers(newPhoneNumbers);
        } else {
          toast.error("Произошла ошибка , попрообуйте снова!");
        }
      }
    );
  };
  const handleRemoveEmailNumber = (index, phoneNumber) => {
    const newemailNumbers = emailNumbers.filter((_, i) => i !== index);

    dispatch(profileDeleteAdditional({ phone_email: phoneNumber })).then(
      (res) => {
        if (res.payload.status === 200) {
          toast.success("Успешно Удалено!");
          setEmailNumbers(newemailNumbers);
        } else {
          toast.error("Произошла ошибка , попрообуйте снова!");
        }
      }
    );
  };
  return (
    <>
      <div className="settings__item">
        {/* Контактные данные */}
        <div className="settings__item-title">Контактные данные</div>
        <div className="settings__item-text">
          Используются для идентификации в системе. Обязательно подтвердите свою
          почту, чтобы иметь доступ к платформе. Для вашей безопасности
          изменение данных возможно только через персонального менеджера.
        </div>
        <div className="input-tow-col">
          {/* Телефон */}
          <div className="input-wrap">
            <span>Номер телефона</span>
            <input
              type="phone"
              disabled
              className="input-style input-verify-icon"
              placeholder="Не указано"
              value={user?.data?.user[0]?.phone}
              required
            />
          </div>
          {/*  */}
          {/* Email */}
          <div
            className={`input-wrap redy-get-code ${
              errorMessage !== "" && "requerid-filed"
            }`}
          >
            <span>
              E-Mail<span className="red-gradient-text">*</span>
            </span>
            {!user?.data?.user[0]?.email_confirm &&
              (active ? (
                <button
                  type="button"
                  className="edit-btn opacity-100"
                  onClick={onClickEditMail}
                ></button>
              ) : (
                <button
                  type="button"
                  className="get-code"
                  onClick={onClickEmail}
                ></button>
              ))}

            <input
              type="email"
              className={`input-style ${
                user?.data?.user[0]?.email_confirm && "input-verify-icon"
              }`}
              placeholder="Не указано"
              disabled={active || user?.data?.user[0]?.email_confirm}
              name="email"
              defaultValue={
                user && user?.data?.user[0]?.email?.includes("@")
                  ? user?.data?.user[0]?.email
                  : ""
              }
              onChange={(e) => setMail({ mail: e.target.value })}
            />
            {errorMessage !== "" && (
              <div className="input-alert red-gradient-text">
                *{errorMessage}
              </div>
            )}
          </div>

          {isLoading && (
            <div className="input-wrap ml-auto d-flex align-items-center justify-content-center">
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Отправка кода</span>
            </div>
          )}
          {active && (
            <div
              className={`input-wrap ml-auto ${
                errorCode !== "" && "requerid-filed"
              }`}
            >
              <span>
                Код с E-Mail<span className="red-gradient-text">*</span>
              </span>
              {errorCode !== "" ? (
                <button
                  type="button"
                  className="confirm-code-disable opacity-100"
                ></button>
              ) : (
                <button
                  type="button"
                  className="confirm-code opacity-100"
                  onClick={onClickCode}
                ></button>
              )}

              <input
                type="number"
                className="input-style input-verify-icon-circle-none "
                placeholder="Введите код"
                onChange={handleCode}
              />
              {errorCode !== "" && (
                <div className="input-alert red-gradient-text">
                  *{errorCode}
                </div>
              )}
            </div>
          )}

          {/*  */}
        </div>
        {/*  */}

        <div className="settings__item-title mt-2">
          Дополнительные контакты:
        </div>
        <div className="input-tow-col">
          <div className="input-wrap">
            {phoneNumbers.map((phoneNumber, index) => (
              <div className="input-additional-phone">
                <span>Номер телефона</span>
                <button
                  type="button"
                  className="input-additional-phone-delete"
                  onClick={(e) => handleRemovePhoneNumber(index, phoneNumber)}
                >
                  <svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 3.25H9V2.625C9 2.12772 8.80246 1.65081 8.45083 1.29917C8.09919 0.947544 7.62228 0.75 7.125 0.75H5.875C5.37772 0.75 4.90081 0.947544 4.54917 1.29917C4.19754 1.65081 4 2.12772 4 2.625V3.25H1.5C1.33424 3.25 1.17527 3.31585 1.05806 3.43306C0.940848 3.55027 0.875 3.70924 0.875 3.875C0.875 4.04076 0.940848 4.19973 1.05806 4.31694C1.17527 4.43415 1.33424 4.5 1.5 4.5H2.125V11.375C2.125 11.8723 2.32254 12.3492 2.67417 12.7008C3.02581 13.0525 3.50272 13.25 4 13.25H9C9.49728 13.25 9.97419 13.0525 10.3258 12.7008C10.6775 12.3492 10.875 11.8723 10.875 11.375V4.5H11.5C11.6658 4.5 11.8247 4.43415 11.9419 4.31694C12.0592 4.19973 12.125 4.04076 12.125 3.875C12.125 3.70924 12.0592 3.55027 11.9419 3.43306C11.8247 3.31585 11.6658 3.25 11.5 3.25ZM5.25 2.625C5.25 2.45924 5.31585 2.30027 5.43306 2.18306C5.55027 2.06585 5.70924 2 5.875 2H7.125C7.29076 2 7.44973 2.06585 7.56694 2.18306C7.68415 2.30027 7.75 2.45924 7.75 2.625V3.25H5.25V2.625ZM9.625 11.375C9.625 11.5408 9.55915 11.6997 9.44194 11.8169C9.32473 11.9342 9.16576 12 9 12H4C3.83424 12 3.67527 11.9342 3.55806 11.8169C3.44085 11.6997 3.375 11.5408 3.375 11.375V4.5H9.625V11.375Z"
                      fill="url(#paint0_linear_2179_5152)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2179_5152"
                        x1="1.76"
                        y1="0.75001"
                        x2="11.8017"
                        y2="1.01312"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF6C6C" />
                        <stop offset="1" stopColor="#F15151" />
                      </linearGradient>
                    </defs>
                  </svg>
                </button>
                <ReactInputMask
                  mask={"+9 (999) 999-99-99"}
                  maskChar="_"
                  name="additional_phone"
                  className="input-style"
                  onChange={(e) => handleChange(e, index)}
                  defaultValue={phoneNumber}
                  placeholder={"+7 (___) ___-__-__"}
                  required
                />
              </div>
            ))}
            <div
              className="contact__details-additional-phone"
              onClick={handleAddPhoneNumber}
            >
              + Добавить телефон
            </div>
          </div>
          <div className="input-wrap">
            {emailNumbers.map((phoneNumber, index) => (
              <div className="input-additional-phone">
                <span>E-Mail</span>
                <button
                  type="button"
                  className="input-additional-phone-delete"
                  onClick={() => handleRemoveEmailNumber(index, phoneNumber)}
                >
                  <svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 3.25H9V2.625C9 2.12772 8.80246 1.65081 8.45083 1.29917C8.09919 0.947544 7.62228 0.75 7.125 0.75H5.875C5.37772 0.75 4.90081 0.947544 4.54917 1.29917C4.19754 1.65081 4 2.12772 4 2.625V3.25H1.5C1.33424 3.25 1.17527 3.31585 1.05806 3.43306C0.940848 3.55027 0.875 3.70924 0.875 3.875C0.875 4.04076 0.940848 4.19973 1.05806 4.31694C1.17527 4.43415 1.33424 4.5 1.5 4.5H2.125V11.375C2.125 11.8723 2.32254 12.3492 2.67417 12.7008C3.02581 13.0525 3.50272 13.25 4 13.25H9C9.49728 13.25 9.97419 13.0525 10.3258 12.7008C10.6775 12.3492 10.875 11.8723 10.875 11.375V4.5H11.5C11.6658 4.5 11.8247 4.43415 11.9419 4.31694C12.0592 4.19973 12.125 4.04076 12.125 3.875C12.125 3.70924 12.0592 3.55027 11.9419 3.43306C11.8247 3.31585 11.6658 3.25 11.5 3.25ZM5.25 2.625C5.25 2.45924 5.31585 2.30027 5.43306 2.18306C5.55027 2.06585 5.70924 2 5.875 2H7.125C7.29076 2 7.44973 2.06585 7.56694 2.18306C7.68415 2.30027 7.75 2.45924 7.75 2.625V3.25H5.25V2.625ZM9.625 11.375C9.625 11.5408 9.55915 11.6997 9.44194 11.8169C9.32473 11.9342 9.16576 12 9 12H4C3.83424 12 3.67527 11.9342 3.55806 11.8169C3.44085 11.6997 3.375 11.5408 3.375 11.375V4.5H9.625V11.375Z"
                      fill="url(#paint0_linear_2179_5152)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2179_5152"
                        x1="1.76"
                        y1="0.75001"
                        x2="11.8017"
                        y2="1.01312"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF6C6C" />
                        <stop offset="1" stopColor="#F15151" />
                      </linearGradient>
                    </defs>
                  </svg>
                </button>
                <input
                  name="additional_email"
                  type="email"
                  onChange={(e) => handleChange(e, index)}
                  className="input-style"
                  placeholder="Не указано"
                  defaultValue={phoneNumber}
                  required
                />
              </div>
            ))}
            <div
              className="contact__details-additional-phone"
              onClick={handleAddEmailNumber}
            >
              + Добавить E-Mail
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactDetails;
