import { getMessaging, getToken } from "firebase/messaging";
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AnimatedPage from "../../components/animated/animated";
import AuthForm from "../../components/auth-form/auth-form";
import ButtonWithBackground from "../../components/button/button-with-background";
import { appFirebase } from "../../firebase";
import eye_close from "../../images/eye-close.svg";
import eye from "../../images/eye.svg";
import { login } from "../../store/auth/login/loginSlice";

function Login() {
  const [loginData, setLoginData] = useState({});
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [countryCode, setCountryCode] = useState("ru");
  const maskMap = {
    ru: "+9 (999) 999-99-99",
    kg: "+999 999 999 999",
  };
  const dispatch = useDispatch();
  const result = useSelector((state) => state.login);
  const user = JSON.parse(localStorage.getItem("web_vinz"));

  useEffect(() => {
    if (user !== null) {
      if (["Seller"].includes(user.role)) {
        window.location.replace("/my-auto");
      } else if (["Manager", "ManagerPRO", "Admin"].includes(user?.role)) {
        window.location.replace("/admin/application/0");
      } else if (["Dealer", "Pro", "User"].includes(user?.role)) {
        window.location.replace("/setting");
      }
    }
  }, [user]);

  useEffect(() => {
    const messaging = getMessaging(appFirebase);
    getToken(messaging, { vapidKey: process.env.REACT_APP_VALID_KEY })
      .then((currentToken) => {
        if (currentToken) {
          setLoginData({ ...loginData, firebase_token: currentToken });
        }
      })
      .catch((err) => {});
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(loginData))
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success("Вы вошли в приложение!");
          if (["Seller"].includes(response.payload.data?.role)) {
            window.location.replace("/my-auto");
          } else if (
            ["Manager", "ManagerPRO", "Admin"].includes(
              response.payload.data?.role
            )
          ) {
            window.location.replace("/admin/application/0");
          } else if (
            ["Dealer", "Pro", "User"].includes(response.payload.data?.role)
          ) {
            window.location.replace("/setting");
          }
        } else {
          toast.error("Неправильный логин или пароль.");
        }
      })
      .catch(() => {
        toast.error("Произошла ошибка!");
      });
  };

  const handleCountryChange = (e) => {
    setCountryCode(e.target.value);
  };

  return (
    <AnimatedPage>
      <AuthForm
        title="Авторизация"
        text="Ещё нет аккаунта?"
        link="/register"
        resetPassword
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <select
              value={countryCode}
              onChange={handleCountryChange}
              className="form-select"
            >
              <option value="ru">Россия</option>
              <option value="kg">Кыргызстан</option>
            </select>
          </div>
          <div className="position-relative">
            <ReactInputMask
              mask={maskMap[countryCode]}
              maskChar="_"
              name="phone"
              className="mb-3 mt-4"
              onChange={handleChange}
              placeholder={
                countryCode === "ru" ? "+7 (___) ___-__-__" : "+996 ___ ___ ___"
              }
              required
            />
          </div>
          <div className="position-relative">
            <input
              type={isPasswordShown ? "text" : "password"}
              name="password"
              placeholder="Введите пароль"
              className="mb-3"
              onChange={handleChange}
              required
            />
            <div
              onClick={() => setIsPasswordShown(!isPasswordShown)}
              className="login__show-password"
            >
              <img
                src={isPasswordShown ? eye_close : eye}
                alt="Toggle visibility"
              />
            </div>
          </div>
          <ButtonWithBackground type="submit" disabled={result?.isLoading}>
            {result?.isLoading ? "Идет проверка..." : "Войти"}
          </ButtonWithBackground>
        </form>
      </AuthForm>
    </AnimatedPage>
  );
}

export default Login;
