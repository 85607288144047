export const extractVideoID = (url) => {
  const regexPatterns = [
    /youtu\.be\/([^#\&\?]*).*/, // Обработка коротких ссылок
    /\?v=([^#\&\?]*).*/, // Обработка стандартных ссылок
    /shorts\/([^#\&\?]*).*/, // Обработка ссылок на шорты
  ];

  for (let pattern of regexPatterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }

  return null; // Возвращает null, если идентификатор не найден
};
