import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import proService from "../proService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// proSend

export const proSend = createAsyncThunk("pro/send", async (thunkAPI) => {
  try {
    return await proService.proSend();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data) ||
      error.message ||
      error.toSrting();
    return thunkAPI.rejectWithValue(message);
  }
});
//

const proSlice = createSlice({
  name: "proSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(proSend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(proSend.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(proSend.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export default proSlice.reducer;
