import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const FileUploadForm = ({ docs, setCreateData, createData }) => {
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    setFiles((prevFiles) => prevFiles.concat(selectedFilesArray));

    const fileReaders = selectedFilesArray.map((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      return fileReader;
    });

    Promise.all(fileReaders).then((results) => {
      const previewUrls = results.map((result) => result.result);
      setPreviewUrls((prevUrls) => [...prevUrls, ...previewUrls]);
    });
  };

  useEffect(() => {
    setCreateData({ ...createData, docs: files[0] });
  }, [files]);
  useEffect(() => {
    if (docs) {
      const urls = docs.map((doc) => doc.url);
      setPreviewUrls(urls);

      setCreateData({ ...createData, docs: urls });
    }
  }, []);

  const failDelete = (name) => {
    const failDelte = files.filter((item) => item.name !== name);
    setFiles(failDelte);
    setPreviewUrls(failDelte);
  };

  return (
    <>
      <h5>Отчёт по VIN</h5>
      <div className="d-flex align-items-center">
        <input
          type="file"
          multiple
          onChange={handleFileInputChange}
          ref={fileInputRef}
          className="d-none"
          id="reports_file"
        />
        <label for="reports_file" className="add-car-data__add-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5001 11.667C17.2791 11.667 17.0671 11.7548 16.9108 11.9111C16.7545 12.0674 16.6667 12.2793 16.6667 12.5003V15.8337C16.6667 16.0547 16.579 16.2666 16.4227 16.4229C16.2664 16.5792 16.0544 16.667 15.8334 16.667H4.16675C3.94573 16.667 3.73377 16.5792 3.57749 16.4229C3.42121 16.2666 3.33341 16.0547 3.33341 15.8337V12.5003C3.33341 12.2793 3.24562 12.0674 3.08934 11.9111C2.93306 11.7548 2.7211 11.667 2.50008 11.667C2.27907 11.667 2.06711 11.7548 1.91083 11.9111C1.75455 12.0674 1.66675 12.2793 1.66675 12.5003V15.8337C1.66675 16.4967 1.93014 17.1326 2.39898 17.6014C2.86782 18.0703 3.50371 18.3337 4.16675 18.3337H15.8334C16.4965 18.3337 17.1323 18.0703 17.6012 17.6014C18.07 17.1326 18.3334 16.4967 18.3334 15.8337V12.5003C18.3334 12.2793 18.2456 12.0674 18.0893 11.9111C17.9331 11.7548 17.7211 11.667 17.5001 11.667ZM9.40841 13.092C9.48767 13.1679 9.58112 13.2273 9.68341 13.267C9.78316 13.3111 9.89102 13.3339 10.0001 13.3339C10.1091 13.3339 10.217 13.3111 10.3167 13.267C10.419 13.2273 10.5125 13.1679 10.5917 13.092L13.9251 9.75866C14.082 9.60174 14.1702 9.38891 14.1702 9.16699C14.1702 8.94507 14.082 8.73224 13.9251 8.57533C13.7682 8.41841 13.5553 8.33025 13.3334 8.33025C13.1115 8.33025 12.8987 8.41841 12.7417 8.57533L10.8334 10.492V2.50033C10.8334 2.27931 10.7456 2.06735 10.5893 1.91107C10.4331 1.75479 10.2211 1.66699 10.0001 1.66699C9.77907 1.66699 9.56711 1.75479 9.41083 1.91107C9.25455 2.06735 9.16675 2.27931 9.16675 2.50033V10.492L7.25841 8.57533C7.18072 8.49763 7.08847 8.43599 6.98696 8.39394C6.88544 8.35189 6.77663 8.33025 6.66675 8.33025C6.55687 8.33025 6.44806 8.35189 6.34654 8.39394C6.24502 8.43599 6.15278 8.49763 6.07508 8.57533C5.99738 8.65302 5.93575 8.74527 5.8937 8.84678C5.85165 8.9483 5.83 9.05711 5.83 9.16699C5.83 9.27687 5.85165 9.38568 5.8937 9.4872C5.93575 9.58872 5.99738 9.68096 6.07508 9.75866L9.40841 13.092Z"
              fill="#8D8796"
            ></path>
          </svg>
          <span>Загрузить pdf</span>
        </label>
        {previewUrls.length > 0 &&
          previewUrls.map((previewUrl, index) => (
            <>
              <span className="add-car-data__report-name" key={index}>
                {files[index]?.name}
              </span>
              <button
                type="button"
                className="remove-btn"
                onClick={(e) => failDelete(files[index]?.name)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M13.3333 3.99967H10.6667V3.33301C10.6667 2.80257 10.456 2.29387 10.0809 1.91879C9.70581 1.54372 9.1971 1.33301 8.66667 1.33301H7.33333C6.8029 1.33301 6.29419 1.54372 5.91912 1.91879C5.54405 2.29387 5.33333 2.80257 5.33333 3.33301V3.99967H2.66667C2.48986 3.99967 2.32029 4.06991 2.19526 4.19494C2.07024 4.31996 2 4.48953 2 4.66634C2 4.84315 2.07024 5.01272 2.19526 5.13775C2.32029 5.26277 2.48986 5.33301 2.66667 5.33301H3.33333V12.6663C3.33333 13.1968 3.54405 13.7055 3.91912 14.0806C4.29419 14.4556 4.8029 14.6663 5.33333 14.6663H10.6667C11.1971 14.6663 11.7058 14.4556 12.0809 14.0806C12.456 13.7055 12.6667 13.1968 12.6667 12.6663V5.33301H13.3333C13.5101 5.33301 13.6797 5.26277 13.8047 5.13775C13.9298 5.01272 14 4.84315 14 4.66634C14 4.48953 13.9298 4.31996 13.8047 4.19494C13.6797 4.06991 13.5101 3.99967 13.3333 3.99967ZM6.66667 3.33301C6.66667 3.1562 6.7369 2.98663 6.86193 2.8616C6.98695 2.73658 7.15652 2.66634 7.33333 2.66634H8.66667C8.84348 2.66634 9.01305 2.73658 9.13807 2.8616C9.2631 2.98663 9.33333 3.1562 9.33333 3.33301V3.99967H6.66667V3.33301ZM11.3333 12.6663C11.3333 12.8432 11.2631 13.0127 11.1381 13.1377C11.013 13.2628 10.8435 13.333 10.6667 13.333H5.33333C5.15652 13.333 4.98695 13.2628 4.86193 13.1377C4.7369 13.0127 4.66667 12.8432 4.66667 12.6663V5.33301H11.3333V12.6663Z"
                    fill="#8D8796"
                  />
                </svg>
              </button>
            </>
          ))}
      </div>
    </>
  );
};

export default FileUploadForm;
