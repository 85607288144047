import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import AnimatedPage from "../../components/animated/animated";
import AuthForm from "../../components/auth-form/auth-form";
import ButtonWithBackground from "../../components/button/button-with-background";
import { register } from "../../store/auth/register/registerSlice";

function Register() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem("web_vinz");
  useEffect(() => {
    if (user !== null) {
      navigate("/setting");
    }
  }, [user]);
  const result = useSelector((state) => state.register);

  const phonePattern = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    if (!phonePattern.test(value)) {
      setPhoneNumberError("Неправильный формат номера телефона");
    } else {
      setPhoneNumberError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(register({phone: phoneNumber})).then((res) => {
      if (res.payload.status === 200) {
        navigate("/verification");
      }
    });
  };
  return (
    <AnimatedPage>
      <AuthForm title="Добро пожаловать!" text="Уже есть аккаунт" link="/login">
        <form onSubmit={handleSubmit}>
          <div className="position-relative">
            <ReactInputMask
              mask="+9 (999) 999-99-99"
              maskChar="_"
              name="phone"
              className="mb-3 mt-4"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="+7 (___) ___-__-__"
              required
            />
            {phoneNumberError && (
              <p className="error-message">{phoneNumberError}</p>
            )}
          </div>
          <ButtonWithBackground>
            {result && result?.isLoading ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Идет проверка...</span>
              </>
            ) : (
              "Получить код"
            )}
          </ButtonWithBackground>
        </form>
      </AuthForm>
    </AnimatedPage>
  );
}

export default Register;
