import axios from "axios";

const API_URL = "https://city.kuleshov.studio/api/get";
const axiosCity = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
  withCredentials: true,
});

// Добавляем интерсептор для нового экземпляра axios
// axiosCity.interceptors.request.use(function (config) {
//   config.headers.Authorization = `Bearer 162|sK53DHRsqJOOAqKmfb69OPAnUKqeJOZ8HDyoIbZdf57db074`;
//   return config;
// });

// cityGet get
const cityGet = async (query) => {
  const response = await axiosCity.get(API_URL, {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

const cityService = {
  cityGet,
};

export default cityService;
