import axios from "axios";

const API_URL = "/v1/rate";

// rate
const rateStore = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

const rateService = {
  rateStore,
};

export default rateService;
