import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/header/header";
import LotsModalPrice from "../../../components/lots/lots-modal-price/lots-modal-price";
import LotsPrice from "../../../components/lots/lots-price/lots-price";
import Pdf from "../../../components/pdf/pdf";
import Preloader from "../../../components/prelouder/prelouder";
import RatesChangePrice from "../../../components/rates/rates-change-price";
import RatesStatus from "../../../components/rates/rates-status/rates-status";
import Sidebar from "../../../components/sidebar/sidebar";
import SingleAuction from "../../../components/single-auction/single-auction";
import {
  singleAuction,
  singleAuctionReset,
} from "../../../store/auction/singleAuctionSlice/singleAuctionSlice";
import { profile } from "../../../store/profile/profileGetSlice/profileGetSlice";

function LotsDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { single } = useSelector((state) => state.singleLot);
  const { user } = useSelector((state) => state.profileGet);
  const [show, setShow] = useState(false);
  const [showChangePrice, setShowChangePrice] = useState(false);
  const [data, setData] = useState({});
  const [errorPrice, setErrorPrice] = useState("");

  useEffect(() => {
    dispatch(singleAuctionReset());
    dispatch(singleAuction(id));
    dispatch(profile());
  }, [id]);
  const isProDealer =
    user?.data?.role === "Pro" || user?.data?.role === "Dealer";
  const handleClose = () => setShow(false);
  const [price, setPrice] = useState({});
  const formattedDate = moment(single?.data?.greade?.end_auction).format(
    "D MMMM YYYY [в] HH:mm"
  );

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Sidebar>
      {single && Object.keys(single).length !== 0 ? (
        <div className="main__data">
          <Header
            title={single?.data?.greade?.car_title}
            subtitle="Карточка автомобиля"
          >
            <div
              onClick={goBack}
              className="main__data-head-back cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.05006 9.40834L11.7667 4.70001C11.8442 4.6219 11.9364 4.5599 12.0379 4.5176C12.1395 4.47529 12.2484 4.45351 12.3584 4.45351C12.4684 4.45351 12.5773 4.47529 12.6789 4.5176C12.7804 4.5599 12.8726 4.6219 12.9501 4.70001C13.1053 4.85614 13.1924 5.06735 13.1924 5.2875C13.1924 5.50766 13.1053 5.71887 12.9501 5.87501L8.82506 10.0417L12.9501 14.1667C13.1053 14.3228 13.1924 14.534 13.1924 14.7542C13.1924 14.9743 13.1053 15.1855 12.9501 15.3417C12.8729 15.4204 12.7808 15.4831 12.6793 15.526C12.5777 15.5689 12.4686 15.5912 12.3584 15.5917C12.2481 15.5912 12.1391 15.5689 12.0375 15.526C11.9359 15.4831 11.8439 15.4204 11.7667 15.3417L7.05006 10.6333C6.96547 10.5553 6.89796 10.4606 6.85179 10.3552C6.80562 10.2498 6.78178 10.1359 6.78178 10.0208C6.78178 9.90575 6.80562 9.79192 6.85179 9.6865C6.89796 9.58108 6.96547 9.48637 7.05006 9.40834Z"
                  fill="#8D8796"
                />
              </svg>
            </div>
          </Header>
          {single && (
            <SingleAuction
              single={single?.data?.greade}
              accessories={single?.data?.accessories}
              reports={single?.data?.reports}
            >
              {isProDealer &&
                (single?.data?.greade?.greade_price.length === 0 ? (
                  <>
                    <div className="card__item-number mb-2">
                      № аукциона <span>{single?.data?.greade?.id}</span>
                    </div>
                    <LotsPrice
                      item={single?.data?.greade}
                      setShow={setShow}
                      setPrice={setPrice}
                      price={price}
                    />
                    <div className="detail__status mt-3">
                      Аукцион закончится
                      <span className="red-gradient-text">{formattedDate}</span>
                    </div>
                  </>
                ) : (
                  <div className="detail-market">
                    <div className="card__item-number">
                      № аукциона <span>{single?.data?.greade?.id}</span>
                    </div>

                    <div className="detail-market__row">
                      <div className="detail-market__col">
                        <div className="detail-market__price-title">
                          Ваша ставка:
                        </div>
                        <div className="detail-market__price">
                          {single?.data?.greade?.greade_price[0]?.price} ₽
                        </div>
                      </div>
                      <div className="detail-market__col text-right">
                        <RatesStatus item={single?.data?.greade} />
                        {single?.data?.greade?.status_id === 1 && (
                          <>
                            <div
                              className="gray-text-link mt-auto cursor-pointer"
                              onClick={() => {
                                setShowChangePrice(true);
                                setData({
                                  oldprice:
                                    single?.data?.greade?.greade_price[0]
                                      ?.price,
                                  greadeId: single?.data?.greade?.id,
                                });
                                setErrorPrice("");
                              }}
                            >
                              Изменить ставку
                            </div>
                            {showChangePrice && (
                              <RatesChangePrice
                                show={showChangePrice}
                                handleClose={handleClose}
                                setShow={setShowChangePrice}
                                data={data}
                                setData={setData}
                                errorPrice={errorPrice}
                                setErrorPrice={setErrorPrice}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="detail__status">
                      Аукцион закончится
                      <span>{formattedDate}</span>
                    </div>
                  </div>
                ))}
              <Pdf pdf={single?.data?.greade?.pdf?.url}> Скачать PDF </Pdf>
            </SingleAuction>
          )}
          {show && (
            <LotsModalPrice
              show={show}
              handleClose={handleClose}
              data={price}
            />
          )}
        </div>
      ) : (
        <Preloader />
      )}
    </Sidebar>
  );
}

export default LotsDetails;
