import { createAsyncThunk } from "@reduxjs/toolkit";
import errorService from "../errorService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// City get

export const errorPost = createAsyncThunk(
  "error/post",
  async (data, thunkAPI) => {
    try {
      return await errorService.errorSend(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//
