import React, { useEffect } from "react";
import error_img from "../../images/technical.jpg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profile } from "../../store/profile/profileGetSlice/profileGetSlice";
import { useSelector } from "react-redux";

const Error500 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.profileGet);

  const onProfile = () => {
    dispatch(profile()).then((res) => {
      if (res?.payload?.status === 200) {
        window.location.replace("/login");
      } else {
        window.location.replace("/login");
      }
    });
  };
  return (
    <div className="bg-white d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <img src={error_img} className="w-50" />
        {isLoading ? (
          <div className="ml-auto blue-outline-btn w-25 mt-4 mx-auto">
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Проверка связи...</span>
          </div>
        ) : (
          <div
            className="gradient-btn-blue w-25 mt-4 mx-auto curor-pointer"
            onClick={onProfile}
          >
            Попробуйте еще раз
          </div>
        )}
      </div>
    </div>
  );
};

export default Error500;
