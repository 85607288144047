import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import sellerService from "../sellerService";

const initialState = {
  editInfo: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const lotsEdit = createAsyncThunk(
  "auction/lotsEdit",
  async (id, thunkAPI) => {
    try {
      return await sellerService.lotsEdit(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const lotsEditSlice = createSlice({
  name: "lotsEditSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(lotsEdit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lotsEdit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const editInfo = action.payload;
        if (editInfo.data.greade.end_auction) {
          editInfo.data.greade.end_auction = moment(
            editInfo.data.greade.end_auction
          ).format("MM.DD.YYYY HH:mm:ss");
        }
        state.editInfo = editInfo;
      })
      .addCase(lotsEdit.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.editInfo = null;
      });
  },
});

export default lotsEditSlice.reducer;
