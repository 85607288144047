import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationIndex } from "../../../store/organization/organizationSlice/organizationSlice";

function PersonalInformation({ user, data, handleChange }) {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState([]);
  const { organization } = useSelector((state) => state.organization);
  useEffect(() => {
    handleChange(filterState);
  }, [filterState]);

  useEffect(() => {
    dispatch(organizationIndex());
  }, []);

  return (
    <div className="settings__item">
      <div className="settings__item-title">Личная информация</div>

      <div className="input-tow-col">
        {/* Фамилия */}
        <div className="input-wrap">
          <span>
            Фамилия<span className="red-gradient-text">*</span>
          </span>
          <input
            type="text"
            name="last_name"
            className="input-style "
            placeholder="Не указано"
            onChange={handleChange}
            defaultValue={user?.data?.user[0]?.last_name}
            required
          />
          {/* <div className="input-alert red-gradient-text">
    *Обязательное поле
  </div> */}
        </div>
        {/*  */}
        {/* Отчество */}
        <div className="input-wrap">
          <span>
            Отчество<span className="red-gradient-text">*</span>
          </span>
          <input
            type="text"
            name="middle_name"
            defaultValue={user?.data?.user[0]?.middle_name}
            className="input-style "
            placeholder="Не указано"
            onChange={handleChange}
            required
          />
        </div>
        {/*  */}

        {/* Имя */}
        <div className="input-wrap">
          <span>
            Имя<span className="red-gradient-text">*</span>
          </span>
          <input
            type="text"
            name="name"
            className="input-style "
            placeholder="Не указано"
            defaultValue={user?.data?.user[0]?.name}
            onChange={handleChange}
            required
          />
        </div>
        {/*  */}

        {/* ИНН */}
        {(data.legal_contact_person == 2 ||
          data.legal_contact_person == 3 ||
          user.data?.user[0].legal_contact_person == 2 ||
          user.data?.user[0].legal_contact_person == 3) && (
          <div className="input-wrap">
            <span>
              ИНН
              <span className="red-gradient-text">*</span>
            </span>
            <input
              type="text"
              name="legal_inn"
              className="input-style "
              defaultValue={user?.data?.user[0]?.legal_inn}
              onChange={handleChange}
              placeholder="Не указано"
              required
            />
          </div>
        )}
        {/*  */}
        {/* Название организации */}
        {(data.legal_contact_person == 3 ||
          user.data?.user[0].legal_contact_person == 3) && (
          <div className="input-wrap">
            <span>
              Название организации
              <span className="red-gradient-text">*</span>
            </span>
            <input
              type="text"
              name="legal_name"
              className="input-style "
              defaultValue={user?.data?.user[0]?.legal_name}
              onChange={handleChange}
              placeholder="Не указано"
              required
            />
          </div>
        )}

        {/*  */}
        {/* Город */}
        {/* <div className="input-wrap my-0">
          <SearchCity
            createData={data}
            title="Город"
            name="city_id"
            onChangeCity={handleChange}
          />
        </div> */}
        {/*  */}

        {/* Город размещения */}
        {/* <div className="input-wrap">
          <span> Вид деятельности</span>
          {Object.keys(organization).length !== 0 && (
            <FilterSelectSearchs
              filter={organization.data.organizations}
              setFilterState={setFilterState}
              nameInput="organization"
              selectArray={selectOrganization}
              readyArray={user?.data?.user[0].organization}
            />
          )} */}

        {/*  */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default PersonalInformation;
