import React from "react";
import { useSelector } from "react-redux";
import AuthVerification from "../../../components/auth-verification/auth-verification";
import { verification } from "../../../store/auth/verification/verificationSlice";

function Verification() {
  const result = useSelector((state) => state.register);

  return (
    <AuthVerification
      verification={verification}
      result={result}
      success_url={"/login"}
      success_text={"Регистрация прошла успешно. Войдите, используя ваш логин."}
    />
  );
}

export default Verification;
