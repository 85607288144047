import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rateService from "../rateService";

const initialState = {
  result: {}, 
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const rateStore = createAsyncThunk(
  "applications/rateStore",
  async (data, thunkAPI) => {
    try {
      return await rateService.rateStore(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const rateStoreSlice = createSlice({
  name: "rateStoreSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(rateStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(rateStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.result = action.payload;
      })
      .addCase(rateStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.result = null;
      });
  },
});

export default rateStoreSlice.reducer;
