// Собираем массив для ПТС аукциона
export const auto_cat = [
  { value: "new_auto", label: "Новое", name: "cat_auto" },
  { value: "with_mileage", label: "С пробегом", name: "cat_auto" },
  { value: "damaged", label: "Поврежденное", name: "cat_auto" },
];
//

// Собираем массив для ПТС application
export const auto_category = [
  { value: "new_auto", label: "Новое", name: "auto_cat" },
  { value: "with_mileage", label: "С пробегом", name: "auto_cat" },
  { value: "damaged", label: "Поврежденное", name: "auto_cat" },
];
//

// Собираем массив для Диагностика

export const auto_diagnostics = (filterName) => [
  { value: "vinz", label: "Диагностика винз", name: filterName },
  {
    value: "expert",
    label: "Диагностика эксперта",
    name: filterName,
  },
  {
    value: "diagnostic_owner",
    label: "От владельца",
    name: filterName,
  },
];
//
//

// Собираем массив для Место сделки
export const auto_transaction = [
  { value: "on_site", label: "На площадке", name: "attribute_transaction" },
  { value: "on_my_way", label: "В пути", name: "attribute_transaction" },
  { value: "at_the_saller", label: "Под заказ", name: "attribute_transaction" },
];
//

// Собираем массив для С юридическими проблемами как есть
export const auto_legal = [
  { value: "restrictions", label: "Ограничения сняты не будут", name: "legal" },
  { value: "credit", label: "Кредит не будет погашен", name: "legal" },
];
//
