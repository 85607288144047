import React from "react";
import { Link } from "react-router-dom";
import op from "../../../../images/op.svg";

function RepeatButton({ item, setShowRepeat, setId }) {
  return (
    // <span
    //   className="blue-gradient-text cursor-pointer"
    //   onClick={(e) => {
    //     setShowRepeat(true);
    //     setId(item.id);
    //   }}
    // >
    //   Разместить повторно
    // </span>

    <Link
      to={`/repeat-auto/${item.id}`}
      className="blue-gradient-text cursor-pointer repeat-btn d-flex align-items-baseline"
    >
      Разместить повторно
      {item.op_link && (
        <Link
          to={item.op_link}
          target="_blank"
          className="d-flex align-items-center card__item-price-op mx-3"
        >
          <img src={op} alt="" />
          ОП
        </Link>
      )}
    </Link>
  );
}

export default RepeatButton;
