import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import auctionService from "../auctionService";

const initialState = {
  single: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const singleAuction = createAsyncThunk(
  "auction/single",
  async (id, thunkAPI) => {
    try {
      return await auctionService.single(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const singleAuctionSlice = createSlice({
  name: "singleAuctionSlice",
  initialState,
  reducers: {
    singleAuctionReset: (state) => {
      state.single = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleAuction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singleAuction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.single = action.payload;
      })
      .addCase(singleAuction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.single = null;
      });
  },
});

export const { singleAuctionReset } = singleAuctionSlice.actions;

export default singleAuctionSlice.reducer;
