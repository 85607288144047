// Собираем массив  для городов
export const selectCity = (array, name) => {
  const options = [];
  array &&
    array.map((item) => {
      return options.push({
        value: item.id,
        label: item.text,
        name: name,
      });
    });
  return options;
};
//

// Собираем массив для марок
export const selectMark = (array, name) => {
  const options = [];
  array &&
    array.map((item) => {
      return options.push({
        value: item.id,
        label: item.name,
        name: name,
      });
    });
  return options;
};
//

// Собираем массив для организаций
export const selectOrganization = (array, name) => {
  const options = [];
  array &&
    array.map((item) => {
      return options.push({
        value: item,
        label: item,
        name: name,
      });
    });
  return options;
};
//

// Собираем массив для роли
export const selectRoles = (array, name) => {
  const options = [];
  array &&
    array.map((item) => {
      return options.push({
        value: item,
        label: item,
        name: name,
      });
    });
  return options;
};
//

// Собираем массив для страховых команий
export const selectCompany = (array, name) => {
  const options = [];
  array &&
    array.map((item) => {
      return options.push({
        value: item.id,
        label: item.title,
        name: name,
      });
    });
  return options;
};
//
