import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../authService";


const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// verification user

export const resetPasswordPost = createAsyncThunk(
  "auth/resetPasswordPost",
  async (data, thunkAPI) => {
    try {
      return await authService.resetPassword(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const resetPasswordSlice = createSlice({
  name: "resetPasswordSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPasswordPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(resetPasswordPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      });
  },
});

export default resetPasswordSlice.reducer;
