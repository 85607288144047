import React from "react";

function ResetIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="4"
        fill="url(#paint0_linear_3318_1021)"
      />
      <path
        d="M12.4598 15.29L18.1198 9.64001C18.2127 9.54628 18.3233 9.47188 18.4452 9.42111C18.5671 9.37035 18.6978 9.34421 18.8298 9.34421C18.9618 9.34421 19.0925 9.37035 19.2144 9.42111C19.3362 9.47188 19.4468 9.54628 19.5398 9.64001C19.726 9.82737 19.8306 10.0808 19.8306 10.345C19.8306 10.6092 19.726 10.8626 19.5398 11.05L14.5898 16.05L19.5398 21C19.726 21.1874 19.8306 21.4408 19.8306 21.705C19.8306 21.9692 19.726 22.2226 19.5398 22.41C19.4472 22.5045 19.3367 22.5797 19.2148 22.6312C19.093 22.6827 18.9621 22.7095 18.8298 22.71C18.6975 22.7095 18.5666 22.6827 18.4447 22.6312C18.3228 22.5797 18.2124 22.5045 18.1198 22.41L12.4598 16.76C12.3583 16.6664 12.2773 16.5527 12.2219 16.4262C12.1664 16.2997 12.1378 16.1631 12.1378 16.025C12.1378 15.8869 12.1664 15.7503 12.2219 15.6238C12.2773 15.4973 12.3583 15.3836 12.4598 15.29V15.29Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3318_1021"
          x1="2.08072"
          y1="5.16582e-06"
          x2="35.7826"
          y2="2.66822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3AB5F3" />
          <stop offset="1" stopColor="#7F42ED" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ResetIcon;
