import axios from "axios";

const API_URL = "/v1/auth";
const API_URL_V2 = "/v2/auth";
// Register user

const register = async (userData) => {
  const response = await axios.post(API_URL_V2 + "/phone", userData);
  return { data: response.data, status: response.status };
};
//

// Verififcation user
const verification = async (userData) => {
  const response = await axios.post(API_URL + "/register", userData);
  if (response.data) {
    localStorage.setItem("web_vinz", JSON.stringify(response.data));
  }
  return { data: response.data, status: response.status };
};
//

// Login user

const login = async (userData) => {
  const response = await axios.post(API_URL + "/login", userData);

  if (response.data) {
    localStorage.setItem("web_vinz", JSON.stringify(response.data));
  }
  return response.data;
};
//

// Logout  user

const logout = async () => {
  const response = await axios.post(API_URL + "/logout");

  if (response.data) {
    localStorage.removeItem("web_vinz");
  }
  return response.status;
};

//

// Reset password verify user

const resetPasswordVerify = async (userData) => {
  const response = await axios.post(
    API_URL_V2 + "/reset-password-phone",
    userData
  );
  return { data: response.data, status: response.status };
};
//
// Reset password

const resetPassword = async (userData) => {
  const response = await axios.post(API_URL + "/reset-password", userData);

  return { data: response.data, status: response.status };
};
//

const authService = {
  register,
  verification,
  login,
  logout,
  resetPasswordVerify,
  resetPassword,
};

export default authService;
