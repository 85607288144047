export const getBaseUrl = () => {
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://vinz.ru/api";
      break;
    case "development":
    default:
      url = "http://vinz.loc/api";
  }

  return url;
};
