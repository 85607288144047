import React from "react";

const Preloader = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20"></circle>
      </svg>
    </div>
  );
};

export default Preloader;
