import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Sidebar from "../../../../components/sidebar/sidebar";
import { orderImageEditSlice } from "../../../../store/image/image-order-edit/uploadImageEdit";
import { imageTokenData } from "../../../../store/token-for-image/tokenImageSlice/tokenImageSlice";

import { useParams } from "react-router-dom";
import ModalAddtitionalSetting from "../../../../components/admin/application/modal-additional-setting/modal-additional-setting";
import AutoInfo from "../../../../components/create-edit-application/auto-info/auto_info";
import AutoParameters from "../../../../components/create-edit-application/auto-parameters/auto-parameters";
import { applicationsAdminUpdate } from "../../../../store/admin/application/updateSlice/updateSlice";
import { applicationsAdminEdit } from "./../../../../store/admin/application/applicationAdminEditSlice/applicationAdminEditSlice";

function AdminApplicationEdit() {
  const [key, setKey] = useState("auto-tab-1");
  const { id } = useParams();
  const handleSelect = (k) => {
    setKey(k);
  };
  const [isTab1Disabled, setIsTab1Disabled] = useState(false);
  const [isTab2Disabled, setIsTab2Disabled] = useState(false);
  const [attachmentOrder, setAttachmentOrder] = useState({});
  const [show, setShow] = useState();

  const { token } = useSelector((state) => state.imageToken);

  if (
    localStorage.getItem("attachment_token") === null ||
    localStorage.getItem("attachment_token") === "undefined"
  ) {
    localStorage.setItem("attachment_token", token?.data);
  }
  const handleClose = () => setShow(false);

  const [isOtherButtonDisabled, setIsOtherButtonDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [createData, setCreateData] = useState({});
  const [report, setReport] = useState("");
  const { data, isLoading } = useSelector(
    (state) => state.applicationAdminEdit
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(applicationsAdminEdit(id)).then((res) => {
      if (res.payload.status === 200) {
        const data = res.payload.data.data;
        setReport(res.payload?.data?.report[0]?.url);
        setCreateData({
          ...createData,
          ...data,
        });
      }
    });
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(imageTokenData());
  }, []);
  // Валидация для кнопки перехода на второй таб
  useEffect(() => {
    // Валидация первого таба
    const tab1Inputs =
      createData.vin &&
      createData.auto_cat &&
      createData.attribute_diagnostics &&
      createData.attribute_transaction &&
      createData.region_id;
    setIsOtherButtonDisabled(!tab1Inputs);
    //
    // Валидация второго таба
    let tab2Inputs = createData.car_type_id && createData.car_mark_id;
    if (createData.car_type_id === 27) tab2Inputs = true;

    setIsTab2Disabled(tab2Inputs);
    //

    // Валидация кнопок для создание лота
    const bothTabsInputs = tab1Inputs && tab2Inputs;
    setIsSubmitDisabled(bothTabsInputs);
    //
  }, [createData]);
  //
  const renderContent = () => {
    switch (key) {
      case "auto-tab-1":
        return (
          <AutoParameters
            setKey={setKey}
            setCreateData={setCreateData}
            createData={createData}
            isTab1Disabled={isTab1Disabled}
            setIsTab1Disabled={setIsTab1Disabled}
            setIsOtherButtonDisabled={setIsOtherButtonDisabled}
            keyTab={key}
            market={true}
          />
        );
      case "auto-tab-2":
        return (
          <AutoInfo
            setCreateData={setCreateData}
            createData={createData}
            keyTab={key}
            editId={id}
            isTab2Disabled={isTab2Disabled}
            isSubmitDisabled={isSubmitDisabled}
            setAttachmentOrder={setAttachmentOrder}
            market={true}
            image={data?.attachments}
            report={report}
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    Object.keys(attachmentOrder).length !== 0
      ? dispatch(orderImageEditSlice(attachmentOrder)).then((res) => {
          dispatch(applicationsAdminUpdate({ id: id, data: createData })).then(
            (res) => {
              if (res.payload.status === 200) {
                toast.success("Успешно сохранен");
                window.location.replace("/admin/application/0 ");
              } else {
                toast.error("Произошла ошибка");
              }
            }
          );
        })
      : dispatch(applicationsAdminUpdate({ id: id, data: createData })).then(
          (res) => {
            if (res.payload.status === 200) {
              toast.success("Успешно сохранен");
              window.location.replace("/admin/application/0 ");
            } else {
              toast.error("Произошла ошибка");
            }
          }
        );
  };
  return (
    <Sidebar>
      <form className="main__data">
        <div className="main__data-head">
          <div className="main__data-info">
            <div className="main__data-heder-title">
              Редактирование автомобиля в маркет
            </div>
          </div>
          {isLoading ? (
            <div className="ml-auto blue-outline-btn w-25">
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Идет Процесс...</span>
            </div>
          ) : (
            <div className="d-flex ml-auto">
              <button
                type="button"
                onClick={(e) => setShow(true)}
                className={`gradient-btn-blue ml-4 ${
                  isSubmitDisabled ? "" : "disablet"
                } ${isLoading && "disablet"}`}
              >
                Редактирование
              </button>
            </div>
          )}
        </div>
        {/* {!isLoading ? ( */}
        <div className="add-car">
          <Tabs activeKey={key} onSelect={handleSelect}>
            <Tab eventKey="auto-tab-1" title="Параметры авто" />
            <Tab
              eventKey="auto-tab-2"
              title="Информация об автомобиле"
              disabled={isOtherButtonDisabled}
            />
          </Tabs>
          <div className="tab-content">{renderContent()}</div>
        </div>
        {/* ) : (
          <Preloader />
        )} */}
      </form>
      {show && (
        <ModalAddtitionalSetting
          show={show}
          handleClose={handleClose}
          data={data}
          setCreateData={setCreateData}
          onSubmit={onSubmit}
          createData={createData}
        />
      )}
    </Sidebar>
  );
}

export default AdminApplicationEdit;
