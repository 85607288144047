import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Header from "../../../components/header/header";
import Sidebar from "../../../components/sidebar/sidebar";
import { profile } from "../../../store/profile/profileGetSlice/profileGetSlice";
import { profileUpdate } from "../../../store/profile/profileUpdateSlice/profileUpdateSlice";

function AdminProfile() {
  const { user } = useSelector((state) => state.profileGet);
  const { isLoading } = useSelector((state) => state.profileUpdate);
  const [data, setData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profile());
  }, []);

  const handleChange = (e, index) => {
    if (e.target) {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(profileUpdate(data))
      .then((response) => {
        if (response.payload.status == 200) {
          toast.success("Данные успешно сохранены!");
          dispatch(profile());
        } else {
          toast.error("Произошла ошибка!");
        }
      })
      .catch((error) => {
        toast.error("Произошла ошибка!");
      });
  };

  return (
    <Sidebar>
      <div className="main__data">
        <Header title="Профиль" />
        {Object.keys(user).length !== 0 && (
          <>
            <div className="settings__item w-100 mt-4">
              <div className="settings__item-title">Личная информация</div>
              <form onSubmit={onSubmit} className="d-flex align-items-end">
                {/* Имя */}
                <div className="input-wrap w-25 ml-0">
                  <span>
                    Имя*<span className="red-gradient-text">*</span>
                  </span>
                  <input
                    type="text"
                    name="name"
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                    defaultValue={user?.data?.user[0]?.name}
                    required
                  />
                </div>
                {/*  */}
                {/* E-Mail */}
                <div className="input-wrap w-25">
                  <span>
                    E-Mail<span className="red-gradient-text">*</span>
                  </span>
                  <input
                    type="text"
                    name="email"
                    defaultValue={user?.data?.user[0]?.email}
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                    required
                  />
                </div>
                {/*  */}
                {/* Телефон */}
                <div className="input-wrap w-25">
                  <span>
                    Телефон<span className="red-gradient-text">*</span>
                  </span>
                  <input
                    type="text"
                    name="phone"
                    defaultValue={user?.data?.user[0]?.phone}
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                    required
                  />
                </div>
                {/*  */}
                <button
                  type="submit"
                  name="save"
                  className={`gradient-btn-blue admin-btn-padding ml-auto p-3`}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Идет проверка...</span>
                    </>
                  ) : (
                    "Сохранить"
                  )}
                </button>
              </form>
            </div>
            {/* Смена пароля */}
            {/* <div className="settings__item w-100 mt-4">
              <div className="settings__item-title">Смена пароля</div>
              <form onSubmit={onSubmit} className="d-flex align-items-end">
                <div className="input-wrap w-25 ml-0">
                  <span>
                    Имя*<span className="red-gradient-text">*</span>
                  </span>
                  <input
                    type="text"
                    name="password"
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-wrap w-25">
                  <span>
                    E-Mail<span className="red-gradient-text">*</span>
                  </span>
                  <input
                    type="text"
                    name="password"
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                    required
                  />
                </div>

                <button
                  type="submit"
                  name="save"
                  className={`gradient-btn-blue admin-btn-padding ml-auto p-3`}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Идет проверка...</span>
                    </>
                  ) : (
                    "Сохранить"
                  )}
                </button>
              </form>
            </div> */}
            {/*  */}
          </>
        )}
      </div>
    </Sidebar>
  );
}

export default AdminProfile;
