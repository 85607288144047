import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import chatService from "../chatService";

const initialState = {
  chats: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Chat  Get

export const chatGet = createAsyncThunk("chat/Get", async (id, thunkAPI) => {
  try {
    return await chatService.chatsSingle(id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data) ||
      error.message ||
      error.toSrting();
    return thunkAPI.rejectWithValue(message);
  }
});
//

const chatGetSlice = createSlice({
  name: "chatGet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(chatGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(chatGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.chats = action.payload;
      })
      .addCase(chatGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.chats = null;
      });
  },
});

export default chatGetSlice.reducer;
