import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/logo.png";
import { profile } from "../../store/profile/profileGetSlice/profileGetSlice";

import { getDatabase, onValue, ref } from "firebase/database";
import { getMessaging, onMessage } from "firebase/messaging";
import swal from "sweetalert";
import { chatsList } from "../../store/chat/chatListSlice/chatListSlice";
import { notificationsIndex } from "../../store/notifications/notificationsSlice/notificationsSlice";
import { notificationsSeenRequest } from "../../store/notifications/seenSlice/seenSlice";
import Notifications from "../notifications/notifications";
import AdminNav from "./nav/admin-nav";
import BuyNav from "./nav/buy-nav";
import SellerNav from "./nav/seller-nav";

function useFirebaseNotifications() {
  useEffect(() => {
    const messaging = getMessaging();
    return onMessage(messaging, (payload) => {
      if (payload.notification.title !== "Новое сообщение:") {
        swal({
          title: payload.notification.title,
          text: payload.notification.body,
        });
      }
    });
  }, []);
}

function Sidebar(props) {
  const { notifications } = useSelector((state) => state.notifications);
  const { user, isLoading } = useSelector((state) => state.profileGet);
  const header = useSelector((state) => state.header);
  const { list } = useSelector((state) => state.listChats);
  const userLocal = JSON.parse(localStorage.getItem("web_vinz"));
  const { chats } = useSelector((state) => state.chats);
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (userLocal) {
      dispatch(profile());
    }

    if (userLocal && active) {
      dispatch(notificationsIndex());
      dispatch(chatsList({ page: 1 }));
    }
  }, []);

  useEffect(() => {
    if (!chats?.data?.chat_room_id) {
      return;
    }
    const database = getDatabase();
    const knot = `chat_room/${user?.data?.user[0]?.id}`;

    const messagesRef = ref(database, knot);
    return onValue(messagesRef, (snapshot) => {
      if (snapshot.exists()) {
        dispatch(chatsList({ page: 1 }));
      }
    });
  }, [chats?.data?.chat_room_id]);

  useEffect(() => {
    const database = getDatabase();
    const knot = `chat_room/${user?.data?.user[0]?.id}`;
    const notificationMesageRef = ref(database, knot);
    return onValue(notificationMesageRef, (snapshot) => {
      if (snapshot.exists()) {
        dispatch(notificationsIndex());
      }
    });
  }, []);

  useFirebaseNotifications();

  const renderNav = () => {
    const totalChats = list?.data?.[0]?.total_count ?? 0;

    if (["Seller"].includes(userLocal?.role)) {
      return <SellerNav />;
    } else if (["Dealer", "User", "Pro"].includes(userLocal?.role)) {
      return <BuyNav total={totalChats} user={user} />;
    } else if (["Admin", "Manager", "ManagerPRO"].includes(userLocal?.role)) {
      return <AdminNav total={totalChats} />;
    } else {
      return <BuyNav />;
    }
  };

  const onNotification = () => {
    setActive(!active);
    if (active) {
      dispatch(notificationsSeenRequest(user?.data?.user[0].id)).then((res) => {
        if (res.payload.status == 200) {
          dispatch(notificationsIndex());
        }
      });
    }
  };
  return (
    <main className="main d-flex-r">
      <div className={`main__left ${header ? "active" : ""}`}>
        {!isLoading && (
          <>
            <div className="main__left-head">
              <img src={logo} alt="" className="logo" />
              {user && user.data && (
                <a
                  href="#"
                  className={`notif-btn ${
                    notifications?.data?.seen != 0 && notifications?.data?.seen
                      ? "active"
                      : ""
                  }`}
                  onClick={onNotification}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                  >
                    <path
                      d="M10.5 8.385V6C10.4989 4.93734 10.1218 3.90935 9.43546 3.09808C8.74911 2.28681 7.79779 1.74461 6.75 1.5675V0.75C6.75 0.551088 6.67098 0.360322 6.53033 0.21967C6.38968 0.0790176 6.19891 0 6 0C5.80109 0 5.61032 0.0790176 5.46967 0.21967C5.32902 0.360322 5.25 0.551088 5.25 0.75V1.5675C4.2022 1.74461 3.25089 2.28681 2.56454 3.09808C1.87818 3.90935 1.50108 4.93734 1.5 6V8.385C1.06234 8.53973 0.683249 8.82605 0.414704 9.2047C0.146159 9.58335 0.00130463 10.0358 0 10.5V12C0 12.1989 0.0790178 12.3897 0.21967 12.5303C0.360322 12.671 0.551088 12.75 0.75 12.75H3.105C3.27771 13.3855 3.65475 13.9466 4.17795 14.3466C4.70114 14.7465 5.34142 14.9633 6 14.9633C6.65858 14.9633 7.29886 14.7465 7.82205 14.3466C8.34525 13.9466 8.72229 13.3855 8.895 12.75H11.25C11.4489 12.75 11.6397 12.671 11.7803 12.5303C11.921 12.3897 12 12.1989 12 12V10.5C11.9987 10.0358 11.8538 9.58335 11.5853 9.2047C11.3168 8.82605 10.9377 8.53973 10.5 8.385ZM3 6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3C6.79565 3 7.55871 3.31607 8.12132 3.87868C8.68393 4.44129 9 5.20435 9 6V8.25H3V6ZM6 13.5C5.73823 13.4984 5.48143 13.4284 5.25512 13.2968C5.02882 13.1652 4.84088 12.9767 4.71 12.75H7.29C7.15912 12.9767 6.97118 13.1652 6.74488 13.2968C6.51857 13.4284 6.26177 13.4984 6 13.5ZM10.5 11.25H1.5V10.5C1.5 10.3011 1.57902 10.1103 1.71967 9.96967C1.86032 9.82902 2.05109 9.75 2.25 9.75H9.75C9.94891 9.75 10.1397 9.82902 10.2803 9.96967C10.421 10.1103 10.5 10.3011 10.5 10.5V11.25Z"
                      fill="#190D2E"
                    />
                  </svg>
                </a>
              )}
            </div>

            {renderNav()}
          </>
        )}
      </div>
      {active && <Notifications active={active} onClick={onNotification} />}

      {props.children}
    </main>
  );
}

export default Sidebar;
