import axios from "axios";

const API_URL = "/application";

// chatsSingle
const chatsSingle = async (id) => {
  const response = await axios.get(API_URL + `/${id}/chat`);

  return { data: response.data, status: response.status };
};
//

// Отправить сообщение
const chatsSend = async (id, data) => {
  const response = await axios.post(API_URL + `/${id}/messages`, data);
  return { data: response.data, status: response.status };
};
//

// chatsList
const chatsList = async (query) => {
  const response = await axios.get(`/v2/application/auth-user-chats`, {
    params: query,
  });

  return { data: response.data, status: response.status };
};
//

// chatsList
const chatsListManeger = async (id) => {
  const response = await axios.get(API_URL + `/${id}/chats`);
  return { data: response.data, status: response.status };
};
//

// chatsList
const chatsListManegerSingle = async (id) => {
  const response = await axios.get(`/chats/${id}/messages`);
  return { data: response.data, status: response.status };
};
//

// Отправить сообщение в определенный чат
const chatsSendManager = async (id, data) => {
  const response = await axios.post(`/chats/${id}/messages`, data);
  return { data: response.data, status: response.status };
};
//

const chatService = {
  chatsSingle,
  chatsSend,
  chatsList,
  chatsListManegerSingle,
  chatsSendManager,
  chatsListManeger,
};

export default chatService;
