import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../authService";

const initialState = {
  info: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// resetPasswordVerifyPost

export const resetPasswordVerifyPost = createAsyncThunk(
  "auth/resetPasswordVerify",
  async (phone, thunkAPI) => {
    try {
      return await authService.resetPasswordVerify(phone);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const resetPasswordVerifySlice = createSlice({
  name: "resetPasswordVerifySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordVerifyPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPasswordVerifyPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.info = action.payload;
      })
      .addCase(resetPasswordVerifyPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.info = null;
      });
  },
});

export default resetPasswordVerifySlice.reducer;
