import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import Verification404 from "../../components/verification-404/verification-404";

function NotVerification() {
  return (
    <Sidebar>
      <Verification404 />
    </Sidebar>
  );
}

export default NotVerification;
