import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { logShow } from "../../../store/logs/logShowSlice/logShowSlice";

function LogsHistory() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { logs } = useSelector((state) => state.logsList);

  return (
    <>
      {logs && (
        <>
          <h2>История статусов</h2>
          {Object.keys(logs).length !== 0 &&
            logs.data.logs.map((item) => { 
              return (
                item.status && (
                  <div className="d-flex justify-content-between align-items-end logs logs-history">
                    <div className="log-history-status">
                      <div>{item.status}</div>
                    </div>
                    <div>
                      {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </div>
                )
              );
            })}
        </>
      )}
    </>
  );
}

export default LogsHistory;
