import { createSlice } from "@reduxjs/toolkit";

const QueryApplicationSlice = createSlice({
  name: "query/application",
  initialState: {},
  reducers: {
    setQueryApplicationSlice: (state, action) => {
      return action.payload;
    },
  },
});

export const { setQueryApplicationSlice } = QueryApplicationSlice.actions;
export default QueryApplicationSlice.reducer;
