import axios from "axios";

const API_URL = "/v1";
// Загрузка изображение при создание машины
const uploadApi = async (data) => {
  const response = await axios.post(API_URL + "/upload", data);
  return { data: response.data, status: response.status };
};
//

// Удаление изображение
const imageDelete = async (id) => {
  const response = await axios.get("v1/remove/attachment/" + id);
  return { data: response.data, status: response.status };
};
//

// Получение временных изображение
const imageTemparary = async (token) => {
  const response = await axios.get("v1/show/attachment", {
    params: {
      token: token,
    },
  });
  return { data: response.data, status: response.status };
};
//

// Загрузка изображение при обновлении машины
const uploadApiEdit = async (id, data) => {
  const response = await axios.post(API_URL + "/upload/" + id, data);
  return { data: response.data, status: response.status };
};
//

// Сортирвока изображение при обновлении машины
const orderApiEdit = async (id, data) => {
  const response = await axios.post(API_URL + "/attachment/" + id, data);
  return { data: response.data, status: response.status };
};
//

// Обнвление изображение
const updateImage = async (id, data) => {
  const response = await axios.post(
    API_URL + "/applications/upload/update/" + id,
    data
  );
  return { data: response.data, status: response.status };
};
//

const uploadImageService = {
  uploadApi,
  imageDelete,
  imageTemparary,
  uploadApiEdit,
  orderApiEdit,
  updateImage,
};

export default uploadImageService;
