import React from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header/header";
import Sidebar from "../../components/sidebar/sidebar";
import logo2 from "../../images/logo2.png";
import logo3 from "../../images/logo3.png";
import logo from "../../images/logo_new.svg";
import Preloader from "./../../components/prelouder/prelouder";

function ProductsOur() {
  const { user, isLoading } = useSelector((state) => state.profileGet);

  return (
    <Sidebar>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="main__data">
          <Header
            title="Проекты Vinz"
            subtitle="Исследуйте все возможности платформы VINZ"
          />
          <div className="row">
            <div className="row__col-3">
              <div className="row__item our-products-item">
                <div className="our-products-item-head">
                  <img src={logo} alt="" />
                  <p className="our-products-item-active gray-gradient-text-normal">
                    Вы сейчас здесь
                  </p>
                </div>
                <p className="our-products-item-descr">
                  Современный маркетплейс для продажи и покупки авто по всей
                  России.
                </p>
              </div>
            </div>
            <div className="row__col-3">
              <div className="row__item our-products-item">
                <div className="our-products-item-head">
                  <img src={logo2} alt="" />
                  <a
                    href={`https://vinz.ru/autopodbor?name=${user?.data?.user[0]?.name}&phone=${user?.data?.user[0]?.phone}`}
                    className="blue-outline-btn"
                    target="_blank"
                  >
                    Перейти
                  </a>
                </div>
                <p className="our-products-item-descr">
                  Сервис автоподбора по любым параметрам
                </p>
              </div>
            </div>
            <div className="row__col-3">
              <div className="row__item our-products-item">
                <div className="our-products-item-head">
                  <img src={logo3} alt="" />
                  <a
                    href="https://assist.vinz.ru/"
                    className="blue-outline-btn"
                    target="_blank"
                  >
                    Перейти
                  </a>
                </div>
                <p className="our-products-item-descr">
                  Система менеджмента для управления и контроля транспортных
                  средств на стоянках.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Sidebar>
  );
}

export default ProductsOur;
