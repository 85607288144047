import React from "react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { lotsWithDrawn } from "../../../../store/seller/lotsWithdrawnSlice/lotsWithdrawnSlice";
import { lotsIndex } from "../../../../store/seller/lotsIndexSlice/lotsIndexSlice";

function WithDrawnButton({ item }) {
  const dispatch = useDispatch();
  const onClick = () => {
    swal({
      text:
        "Вы уверены, что хотите остановить аукцион? После остановки он будет перенесён в раздел «Снят с аукциона».",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(lotsWithDrawn(item.id));
        swal("Аукцион остановлен", {
          icon: "success",
        }).then(() => {
          dispatch(lotsIndex({ page: 1 }));
        });
      }
    });
  };
  return (
    item.status_id === 1 && (
      <div onClick={onClick} className="cursor-pointer">
        <span className="blue-gradient-text ms-4">Остановить аукцион</span>
      </div>
    )
  );
}

export default WithDrawnButton;
