import axios from "axios";

const API_URL = "/v1/admin/user";

// Получить данные всех пользователей
const usersGet = async () => {
  const response = await axios.get(API_URL);
  return { data: response.data, status: response.status };
};
//

// Получить данные для создание пользователй
const usersCreateGet = async () => {
  const response = await axios.get(API_URL + "/create");
  return { data: response.data, status: response.status };
};
//

// Создать пользователя
const userStore = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

// Получить данные для редактирование пользователй
const userEdit = async (id) => {
  const response = await axios.get(API_URL + "/edit/" + id);
  return { data: response.data, status: response.status };
};
//

const usersService = {
  usersGet,
  usersCreateGet,
  userStore,
  userEdit,
};

export default usersService;
