import {
  Canvas,
  Circle,
  Image as FabricImage,
  Line,
  PencilBrush,
  Polygon,
  Rect,
} from "fabric";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { applicationsAdminEdit } from "../../store/admin/application/applicationAdminEditSlice/applicationAdminEditSlice";
import { updateImageSlice } from "../../store/image/image-update/imageUpdate";
import { uploadImageSlice } from "../../store/image/image-upload/uploadImageSlice";
import { lotsEdit } from "../../store/seller/lotsEditSlice/lotsEditSlice";
import BrushIcon from "../icons/brush";
import ResetIcon from "../icons/reset";
import RotateLeftIcon from "../icons/rotate-left";
import RotateRightIcon from "../icons/rotate-right";
import SaveIcon from "../icons/save";
import TransformIcon from "../icons/transform";
import ZoomInIcon from "../icons/zoom-in";
import ZoomOutIcon from "../icons/zoom-out";
import Preloader from "./../prelouder/prelouder";

function ImageEditorModal({
  image,
  onClose,
  show,
  setSortedList,
  add,
  market,
  setIsDraggingDisabled,
}) {
  const [drawingColor, setDrawingColor] = useState("#000000");
  const [canvas, setCanvas] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [points, setPoints] = useState([]);
  const [polygon, setPolygon] = useState(null);
  const [line, setLine] = useState(null);
  const [startCircle, setStartCircle] = useState(null);
  const [cropRect, setCropRect] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [zoom, setZoom] = useState(1);
  const cropRectRef = useRef(null);
  const [prelouder, setPrelouder] = useState(false);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [brushWidth, setBrushWidth] = useState(5);
  const [drawingTool, setDrawingTool] = useState("brush"); // 'brush', 'square', 'circle'
  const [isDrawingShape, setIsDrawingShape] = useState(false);
  const [startPoint, setStartPoint] = useState(null);

  const { id } = useParams();
  const tokenAttachment = localStorage.getItem("attachment_token");
  const { isLoading } = useSelector((state) => state.imageEditUpload);
  console.log(image);
  const dispatch = useDispatch();

  const initCanvas = useCallback((node) => {
    if (node !== null) {
      const canvasInstance = new Canvas(node, {
        selection: false,
        width: 800,
        height: 600,
      });
      setCanvas(canvasInstance);
      console.log("Canvas initialized", canvasInstance);
    }
  }, []);

  useEffect(() => {
    if (canvas) {
      const rect = canvas.getElement().getBoundingClientRect();
      console.log("Canvas bounding rect:", rect);
      console.log("Canvas size:", canvas.width, canvas.height);
    }
  }, [canvas]);

  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  const loadImage = (url) => {
    const proxyUrl = `http://vinz.loc/proxy?url=${encodeURIComponent(url)}`;
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = proxyUrl;

    img.onload = () => {
      const fabricImg = new FabricImage(img, {
        selectable: false,
        evented: false,
      });

      const scale = Math.min(800 / img.width, 600 / img.height);
      fabricImg.scaleToWidth(img.width * scale);
      fabricImg.scaleToHeight(img.height * scale);

      canvas.clear();
      canvas.add(fabricImg);
      canvas.setDimensions({
        width: img.width * scale,
        height: img.height * scale,
      });
      canvas.renderAll();

      setOriginalImage(fabricImg);
    };

    img.onerror = () => {
      console.error("Failed to load image");
    };
  };

  useEffect(() => {
    if (canvas && image?.url) {
      loadImage(image.url);
    }

    return () => {
      if (canvas) {
        canvas.dispose();
      }
    };
  }, [canvas, image, add]);

  // Логика рисования многоугольника
  useEffect(() => {
    if (!canvas) return;

    const handleMouseDown = (opt) => {
      if (!isDrawing) return;

      const pointer = canvas.getPointer(opt.e);
      const currentPoints = [...points, { x: pointer.x, y: pointer.y }];

      if (points.length > 1 && isCloseToFirstPoint(pointer, points[0])) {
        const newPolygon = new Polygon(points, {
          fill: drawingColor,
          selectable: false,
          evented: false,
        });
        canvas.add(newPolygon);
        resetDrawing();
      } else {
        setPoints(currentPoints);

        if (polygon) canvas.remove(polygon);
        const newPolygon = new Polygon(currentPoints, {
          stroke: drawingColor,
          strokeWidth: 2,
          fill: "rgba(0,0,0,0)",
          selectable: false,
          evented: false,
        });
        canvas.add(newPolygon);
        setPolygon(newPolygon);

        if (line) canvas.remove(line);
        const newLine = new Line([pointer.x, pointer.y, pointer.x, pointer.y], {
          stroke: drawingColor,
          strokeWidth: 2,
          selectable: false,
          evented: false,
        });
        canvas.add(newLine);
        setLine(newLine);
        console.log(newLine);

        if (!startCircle) {
          const circle = new Circle({
            left: pointer.x,
            top: pointer.y,
            radius: 5,
            fill: "red",
            selectable: false,
            evented: false,
          });
          canvas.add(circle);
          setStartCircle(circle);
        }
      }
    };

    const handleMouseMove = (opt) => {
      if (!isDrawing || !line) return;
      const pointer = canvas.getPointer(opt.e);
      line.set({ x2: pointer.x, y2: pointer.y });
      canvas.renderAll();
    };

    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);

    return () => {
      canvas.off("mouse:down", handleMouseDown);
      canvas.off("mouse:move", handleMouseMove);
    };
  }, [canvas, points, drawingColor, line, polygon, startCircle, isDrawing]);

  const isCloseToFirstPoint = (point, firstPoint) => {
    const distance = Math.sqrt(
      Math.pow(point.x - firstPoint.x, 2) + Math.pow(point.y - firstPoint.y, 2)
    );
    return distance < 10;
  };

  const resetDrawing = () => {
    setPoints([]);
    setPolygon(null);
    setLine(null);
    if (startCircle) {
      canvas.remove(startCircle);
      setStartCircle(null);
    }
  };

  const handleSave = async () => {
    setPrelouder(true);
    try {
      const dataURL = canvas.toDataURL({
        format: "png",
        quality: 1,
      });
      const imageData = {
        id: image.id,
        data: { file: dataURL, name: image?.name },
      };

      if (add) {
        const res = await dispatch(
          uploadImageSlice({
            file: dataURL,
            _token: tokenAttachment,
            name: image.name,
          })
        );

        if (res.payload.status === 200) {
          const newImage = { id: res.payload.data.attachments, url: dataURL };
          setSortedList((prevImages) => [...prevImages, newImage]);
          onClose();
          setPrelouder(false);
        }
      } else {
        const res = await dispatch(updateImageSlice(imageData));

        if (res.payload.status === 200) {
          const resEdit = market
            ? await dispatch(applicationsAdminEdit(id))
            : await dispatch(lotsEdit(id));
          if (resEdit.payload.status === 200) {
            setSortedList(
              market
                ? resEdit.payload.data.attachments
                : resEdit.payload.data.greade.images
            );
            onClose();
            setPrelouder(false);
          }
        } else {
          console.error("Error saving image:", res);
        }
      }
    } catch (error) {
      console.error("Error during save operation:", error);
    }
  };

  const handleReset = () => {
    if (canvas && originalImage) {
      canvas.clear();
      canvas.add(originalImage);
      resetDrawing();
      setCropRect(null);
    }
  };

  const rotateImage = (angle) => {
    if (originalImage && canvas) {
      originalImage.rotate((originalImage.angle + angle) % 360);
      canvas.renderAll();
    }
  };

  const handleZoomIn = () => {
    if (canvas) {
      const newZoom = Math.min(zoom + 0.1, 2);
      setZoom(newZoom);
      canvas.setZoom(newZoom);
      canvas.renderAll();
    }
  };

  const handleZoomOut = () => {
    if (canvas) {
      const newZoom = Math.max(zoom - 0.1, 0.5);
      setZoom(newZoom);
      canvas.setZoom(newZoom);
      canvas.renderAll();
    }
  };

  // Обработчик для создания прямоугольника кадрирования
  const addCropRect = () => {
    if (!canvas) return;

    // Удаление старого прямоугольника кадрирования
    if (cropRect) {
      canvas.remove(cropRect);
      setCropRect(null); // Сбрасываем состояние кадра
    }

    // Создание нового прямоугольника кадрирования
    const rect = new Rect({
      left: 100, // Начальная позиция
      top: 100,
      width: 200, // Начальная ширина и высота
      height: 200,
      fill: "rgba(255, 255, 255, 0.3)", // Прозрачная заливка
      stroke: "white", // Белая рамка
      strokeDashArray: [5, 5], // Пунктирная линия
      selectable: true, // Сделаем его интерактивным
      hasControls: true, // Возможность изменения размеров
      lockRotation: true, // Блокировка вращения
    });

    setCropRect(rect); // Сохранение нового прямоугольника
    canvas.add(rect); // Добавляем прямоугольник на холст
    canvas.setActiveObject(rect); // Устанавливаем его активным объектом
    canvas.renderAll(); // Перерисовка холста
    setIsCropping(true); // Включаем режим кадрирования
    setIsDraggingDisabled(true); // Отключаем перетаскивание
  };

  // Применение кадрирования
  const applyCrop = () => {
    if (!canvas || !cropRect) return;

    const { left, top, width, height } = cropRect.getBoundingRect();

    // Создаем временный холст для обрезки изображения
    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = width;
    croppedCanvas.height = height;
    const ctx = croppedCanvas.getContext("2d");

    const img = originalImage.getElement();
    const scaleX = originalImage.scaleX || 1;
    const scaleY = originalImage.scaleY || 1;

    // Кадрируем изображение
    ctx.drawImage(
      img,
      left / scaleX,
      top / scaleY,
      width / scaleX,
      height / scaleY,
      0,
      0,
      width,
      height
    );

    const dataURL = croppedCanvas.toDataURL("image/png");

    // Загрузка обрезанного изображения на холст
    const newImg = new Image();
    newImg.onload = () => {
      const newFabricImg = new FabricImage(newImg, {
        selectable: false,
        evented: false,
      });

      canvas.clear();
      canvas.setWidth(width);
      canvas.setHeight(height);
      canvas.add(newFabricImg);

      setOriginalImage(newFabricImg); // Обновляем изображение в состоянии
      setCropRect(null); // Сбрасываем кадрирование
      setIsCropping(false); // Выходим из режима кадрирования
    };

    newImg.src = dataURL;

    setIsDraggingDisabled(false); // Включаем перетаскивание
  };

  // ДЛя рисования кистью
  const toggleDrawingMode = () => {
    if (canvas) {
      const newMode = !isDrawingMode;
      setIsDrawingMode(newMode);
      canvas.isDrawingMode = newMode;
      canvas.freeDrawingBrush.color = drawingColor;
      canvas.freeDrawingBrush.width = brushWidth;
    }
  };

  const handleBrushWidthChange = (e) => {
    const newWidth = parseInt(e.target.value, 10);
    setBrushWidth(newWidth);
    if (canvas) {
      canvas.freeDrawingBrush.width = newWidth;
    }
  };

  useEffect(() => {
    if (canvas) {
      canvas.freeDrawingBrush = new PencilBrush(canvas);
      canvas.freeDrawingBrush.color = drawingColor;
      canvas.freeDrawingBrush.width = brushWidth;
    }
  }, [canvas]);

  const resetPolygonState = () => {
    setPoints([]);
    setPolygon(null);
    setLine(null);
    if (startCircle) {
      canvas.remove(startCircle);
      setStartCircle(null);
    }
    setIsDrawing(false); // Отключаем режим рисования многоугольника
  };

  const resetCropState = () => {
    if (cropRect) {
      canvas.remove(cropRect); // Удаляем прямоугольник кадрирования
      setCropRect(null);
    }
    setIsCropping(false); // Отключаем режим кадрирования
    setIsDraggingDisabled(false); // Включаем возможность перетаскивания
  };

  const handleToolChange = (tool) => {
    // Сбрасываем многоугольник, если переключаемся на другой инструмент
    if (tool !== "polygon") {
      resetPolygonState();
    }

    // Сбрасываем кадрирование, если переключаемся на другой инструмент
    if (tool !== "crop") {
      resetCropState();
    }

    setDrawingTool(tool);

    // Отключаем кисть, если выбираем не кисть
    if (tool === "brush") {
      setIsDrawingMode(true);
      canvas.isDrawingMode = true;
    } else {
      setIsDrawingMode(false);
      canvas.isDrawingMode = false;
    }

    // Если выбрали кадрирование, отключаем кисть
    if (tool === "crop") {
      resetPolygonState(); // Очищаем многоугольник, если он активен
      setIsDrawingMode(false); // Отключаем кисть
      canvas.isDrawingMode = false; // Отключаем режим рисования
      addCropRect(); // Включаем режим кадрирования
    }
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      ariaHideApp={false}
      contentLabel="Редактирование изображения"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          overflow: "auto",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%", // Можно настроить ширину и высоту под экран
          height: "90%", // 90% от экрана
          maxHeight: "90vh", // Ограничиваем высоту вьюпорта
          maxWidth: "90vw", // Ограничиваем ширину вьюпорта
          overflow: "auto", // Добавляем скролл для контента
          zIndex: 9999,
        },
      }}
    >
      <div className="image-editor-modal">
        <div className="row">
          {prelouder ? (
            <Preloader />
          ) : (
            <>
              {" "}
              <div className="image-editor-modal-panel">
                <div className="controls d-flex">
                  {/* Повернуть вправо */}
                  <button
                    onClick={() => {
                      rotateImage(90);
                      setActiveButton("rotateRight");
                    }}
                    title="Повернуть вправо"
                    className={`${
                      activeButton === "rotateRight" ? "active" : ""
                    } image-editor__button`}
                  >
                    <RotateRightIcon />
                  </button>
                  {/*  */}

                  {/* Повернуть влево */}
                  <button
                    onClick={() => {
                      rotateImage(-90);
                      setActiveButton("rotateLeft");
                    }}
                    title="Повернуть влево"
                    className={`${
                      activeButton === "rotateLeft" ? "active" : ""
                    } image-editor__button`}
                  >
                    <RotateLeftIcon />
                  </button>
                  {/*  */}

                  {/* Кисть для рисования */}
                  <button
                    onClick={() => {
                      handleToolChange("brush ");
                      toggleDrawingMode();
                      setActiveButton("brush");
                    }}
                    title="Кисть"
                    className={`${
                      activeButton ? "leave-active" : ""
                    } image-editor__button`}
                  >
                    <BrushIcon />
                  </button>
                  {/*  */}

                  {/* По точечное закрашивание */}
                  <button
                    onClick={() => {
                      setIsDrawing(true);
                      setActiveButton("polygon");
                      handleToolChange("polygon");
                    }}
                    title="Кисть"
                    className={`${
                      activeButton === "brush" ? "leave-active" : ""
                    } image-editor__button`}
                  >
                    <TransformIcon />
                  </button>
                  {/*  */}

                  <div>
                    <input
                      type="color"
                      id="colorPicker"
                      value={drawingColor}
                      onChange={(e) => setDrawingColor(e.target.value)}
                      style={{ backgroundColor: drawingColor }}
                    />
                  </div>

                  <button
                    onClick={handleSave}
                    title="Сохранить изменения"
                    disabled={isLoading}
                  >
                    <SaveIcon />
                  </button>
                  <button
                    onClick={() => {
                      handleReset();
                      setActiveButton("reset");
                    }}
                    title="Отмена"
                    className={activeButton === "reset" ? "active" : ""}
                  >
                    <ResetIcon />
                  </button>

                  <button
                    onClick={handleZoomIn}
                    title="Увеличить"
                    className={activeButton === "zoomIn" ? "active" : ""}
                  >
                    <ZoomInIcon />
                  </button>
                  <button
                    onClick={handleZoomOut}
                    title="Уменьшить"
                    className={activeButton === "zoomOut" ? "active" : ""}
                  >
                    <ZoomOutIcon />
                  </button>
                  <input
                    type="range"
                    min="1"
                    max="50"
                    value={brushWidth}
                    onChange={handleBrushWidthChange}
                    title="Толщина кисти"
                  />

                  <button
                    onClick={() => {
                      addCropRect();
                      setActiveButton("crop");
                      setIsCropping(true);
                      handleToolChange("crop");
                    }}
                    title="Кадрировать"
                    className={
                      activeButton === "crop"
                        ? "active gradient-btn-blue ml-auto"
                        : "gradient-btn-blue mb-2 ml-auto"
                    }
                  >
                    Кадрировать
                  </button>
                  {isCropping && (
                    <button
                      onClick={applyCrop}
                      title="Применить кадрирование"
                      className="active gradient-btn-blue mb-2 ml-2"
                    >
                      Применить
                    </button>
                  )}
                  {isLoading && "Сохранение..."}

                  <button
                    type="button"
                    className="close-modal"
                    onClick={onClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.94004 8L13.14 3.80667C13.2656 3.68113 13.3361 3.51087 13.3361 3.33333C13.3361 3.1558 13.2656 2.98554 13.14 2.86C13.0145 2.73447 12.8442 2.66394 12.6667 2.66394C12.4892 2.66394 12.3189 2.73447 12.1934 2.86L8.00004 7.06L3.80671 2.86C3.68117 2.73447 3.51091 2.66394 3.33337 2.66394C3.15584 2.66394 2.98558 2.73447 2.86004 2.86C2.7345 2.98554 2.66398 3.1558 2.66398 3.33333C2.66398 3.51087 2.7345 3.68113 2.86004 3.80667L7.06004 8L2.86004 12.1933C2.79756 12.2553 2.74796 12.329 2.71411 12.4103C2.68027 12.4915 2.66284 12.5787 2.66284 12.6667C2.66284 12.7547 2.68027 12.8418 2.71411 12.9231C2.74796 13.0043 2.79756 13.078 2.86004 13.14C2.92202 13.2025 2.99575 13.2521 3.07699 13.2859C3.15823 13.3198 3.24537 13.3372 3.33337 13.3372C3.42138 13.3372 3.50852 13.3198 3.58976 13.2859C3.671 13.2521 3.74473 13.2025 3.80671 13.14L8.00004 8.94L12.1934 13.14C12.2553 13.2025 12.3291 13.2521 12.4103 13.2859C12.4916 13.3198 12.5787 13.3372 12.6667 13.3372C12.7547 13.3372 12.8419 13.3198 12.9231 13.2859C13.0043 13.2521 13.0781 13.2025 13.14 13.14C13.2025 13.078 13.2521 13.0043 13.286 12.9231C13.3198 12.8418 13.3372 12.7547 13.3372 12.6667C13.3372 12.5787 13.3198 12.4915 13.286 12.4103C13.2521 12.329 13.2025 12.2553 13.14 12.1933L8.94004 8Z"
                        fill="#8D8796"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="col-lg-12">
                <canvas
                  ref={initCanvas}
                  width={800}
                  height={600}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseMove={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                  style={{ display: "block" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ImageEditorModal;
