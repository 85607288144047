import axios from "axios";

const API_URL = "/v1/error";

// law Store
const errorSend = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

const errorService = {
  errorSend,
};

export default errorService;
