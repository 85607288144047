import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/sidebar";
import {
  applicationReset,
  applicationsGet,
} from "../../store/applications/applicationsGetSlice/applicationsGetSlice";
import { profile } from "../../store/profile/profileGetSlice/profileGetSlice";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ListOfCars from "../../components/applicatons/applications-list/applications-list";
import Filter from "../../components/filter/filter";
import Preloader from "../../components/prelouder/prelouder";
import { applicationsCheckInStock } from "../../store/applications/applicationsCheckInStockSlice/applicationsCheckInStockSlice";

function Storage() {
  const { applications } = useSelector((state) => state.applicationsGet);
  const [show, setShow] = useState(false);
  const [single, setSingle] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [query, setQuery] = useState();
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(applicationReset());
    dispatch(profile());
    dispatch(applicationsGet({ page: 1, status: 2 }));
  }, []);

  const handlePageChange = (selectedObject) => {
    dispatch(
      applicationsGet({
        page: selectedObject.selected + 1,
        status: 2,
        ...query,
      })
    );
    window.scrollTo(0, 0);
  };

  const [showPhoneContacts, setShowPhoneContacts] = useState({});
  const openPhone = (itemId) => {
    setShowPhoneContacts((prevShowPhoneContacts) => ({
      ...prevShowPhoneContacts,
      [itemId]: true,
    }));
  };
  const { isLoading, count } = useSelector((state) => state.applicationsGet);

  const checkInStock = () => {
    swal({
      title: "Подтвержение действия",
      text: "Мы не гарантируем наличие данного ТС! Нажимая Уточнить в наличии , вы бронируете данное ТС за собой. Наши менеджеры уточнят статус ТС и свяжутся с вами в ближайшее время",
      icon: "warning",
      buttons: ["Отменить", "Да"],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(applicationsCheckInStock());
        swal("Авто успешно забронировано", {
          icon: "success",
        }).then(() => {
          navigate("/garage");
        });
      }
    });
  };

  return (
    <Sidebar>
      {Object.keys(applications).length !== 0 ? (
        <>
          <ListOfCars
            title="Специальные предложения"
            applications={applications}
            show={show}
            handleClose={handleClose}
            openPhone={openPhone}
            single={single}
            setSingle={setSingle}
            setOpenFilter={setOpenFilter}
            openFilter={openFilter}
            setShow={setShow}
            checkInStock={checkInStock}
            handlePageChange={handlePageChange}
            status={2}
          />
          <Filter
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            isLoading={isLoading}
            count={count}
            setQuery={setQuery}
            query={query}
            getData={applicationsGet}
            type={{ type: "car" }}
            status_id={2}
          />
        </>
      ) : (
        <Preloader />
      )}
    </Sidebar>
  );
}

export default Storage;
