import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import applicationAdminService from "../applicationAdminService";

const initialState = {
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Chat  Get

export const applicationsAdminEdit = createAsyncThunk(
  "admin/applicationsAdminEdit",
  async (id, thunkAPI) => {
    try {
      return await applicationAdminService.applicationsAdminEdit(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const applicationsAdminEditSlice = createSlice({
  name: "applicationsAdminEdit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applicationsAdminEdit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsAdminEdit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(applicationsAdminEdit.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.data = null;
      });
  },
});

export default applicationsAdminEditSlice.reducer;
