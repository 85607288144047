import React from "react";
import LogoutComponent from "../../logout/logout";

function ProfileInfo() {
  return (
    <>
      <div className="settings__item">
        <div className="settings__item-title">Информационный блок</div>
        <div className="profile-info__text">
          <span> Для УЧАСТНИКОВ - юридических лиц:</span>
          <ul>
            <li>
              полное наименование, копию Свидетельства о присвоении ИНН, копию
              Свидетельства о присвоении ОГРН;
            </li>
            <li> адрес в пределах места нахождения с указанием индекса;</li>
            <li>
              ФИО уполномоченного представителя юридического лица с приложением
              сканированной копии доверенности, копии паспорта, контактного
              телефона и адреса электронной почты;
            </li>
            <li>
              копию паспорта Генерального директора. выписка из ЕГРЮЛ сделанная
              не более 30 дней назад Свидетельство о постановке на налоговый
              учет 4.2.2. Для УЧАСТНИКОВ – физических лиц:{" "}
            </li>
            <li>ФИО (полностью), копию паспорта с адресом регистрации;</li>
            <li>
              контактный телефон и адрес электронной почты. 4.2.3 Для УЧАСТНИКОВ
              - индивидуальных предпринимателей (далее – «ИП»):
            </li>
            <li>ФИО (полностью), копию паспорта с адресом регистрации;</li>
            <li>телефон и адрес электронной почты;</li>
            <li>
              свидетельство о регистрации в качестве ИП, свидетельство о
              присвоении ИНН. Выписка из ЕГРИП сделанная не более 30 дней назад
              Свидетельство о постановке на налоговый учет
            </li>
          </ul>
        </div>
      </div>
      <LogoutComponent />
    </>
  );
}

export default ProfileInfo;
