import axios from "axios";

const API_URL = "/v1/admin/status";

const statusesGet = async () => {
  const response = await axios.get(API_URL);
  return { data: response.data, status: response.status };
};
//

const statusesService = {
  statusesGet,
};

export default statusesService;
