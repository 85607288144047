import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import profileService from "../profileService";

const initialState = {
  user: {},
  userById: {}, // Добавляем новую переменную для данных запроса по id
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Profile get
export const profile = createAsyncThunk("profile/get", async (id, thunkAPI) => {
  try {
    return await profileService.profileGet(id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const profileGetSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(profile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(profile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (action.meta.arg) {
          // Если есть id, сохраняем данные в userById
          state.userById = action.payload;
        } else {
          // Если id нет, сохраняем данные в user
          state.user = action.payload;
        }
      })
      .addCase(profile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export default profileGetSlice.reducer;
