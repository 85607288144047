import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListOfCars from "../../../components/applicatons/applications-list/applications-list";
import Filter from "../../../components/filter/filter";
import Preloader from "../../../components/prelouder/prelouder";
import Sidebar from "../../../components/sidebar/sidebar";
import { applicationsGuestGet } from "../../../store/applications/applicationsGuest/applicationsGuestSlice";

function ApplicationsGuest() {
  const { applications, isLoading, count } = useSelector(
    (state) => state.applicationsGuest
  );
  const query = useSelector((state) => state.filterQuery);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("web_vinz"));
    dispatch(applicationsGuestGet({ page: 1 }));

    if (user && Object.keys(user).length !== 0) {
      switch (user.role) {
        case "Pro":
          navigate("/applications");
          break;
        case "Dealer":
          navigate("/applications");
          break;
        case "Admin":
          navigate("/admin/application/0");
          break;
        case "Manager":
          navigate("/admin/application/0");
          break;
        case "Seller":
          navigate("/my-auto");
          break;
        default:
          navigate("/");
          break;
      }
    }
  }, []);
  const [show, setShow] = useState(false);
  const [single, setSingle] = useState({});
  // const [query, setQuery] = useState();

  const handlePageChange = (selectedObject) => {
    dispatch(
      applicationsGuestGet({
        page: selectedObject.selected + 1,
        ...query,
        ...{ onlyCount: false },
      })
    );
    window.scrollTo(0, 0);
  };
  const [openFilter, setOpenFilter] = useState(false);
  return (
    <Sidebar>
      {Object.keys(applications).length !== 0 ? (
        <>
          <ListOfCars
            title={"Маркет"}
            applications={applications}
            show={show}
            single={single}
            setSingle={setSingle}
            setOpenFilter={setOpenFilter}
            openFilter={openFilter}
            handlePageChange={handlePageChange}
            setShow={setShow}
          />

          <Filter
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            isLoading={isLoading}
            count={count}
            // setQuery={setQuery}
            query={query}
            getData={applicationsGuestGet}
            type={{ type: "car" }}
          />
        </>
      ) : (
        <Preloader />
      )}
    </Sidebar>
  );
}

export default ApplicationsGuest;
