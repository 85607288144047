import React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidebar/sidebar";

function UnderConstruction() {
  const location = useLocation();
  const { text } = location.state || {
    text: "Функционал временно не доступен ",
  };
  return (
    <Sidebar>
      <div className="container insufficient-rights-page">
        <h1> {text} </h1>
      </div>
    </Sidebar>
  );
}

export default UnderConstruction;
