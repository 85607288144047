import axios from "axios";

const API_URL = "/v1/lots";

// Image get
const imageToken = async () => {
  const response = await axios.get(API_URL + "/create");

  return { data: response.data, status: response.status };
};
//

const imageService = {
  imageToken,
};

export default imageService;
