import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import applicationService from "../applicationsService";

const initialState = {
  count: 0,
  applications: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Profile get

export const applicationsGet = createAsyncThunk(
  "applications/get",
  async (query, thunkAPI) => {
    try {
      return await applicationService.applicationsGet(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const applicationsGetSlice = createSlice({
  name: "applicationsGet",
  initialState,
  reducers: {
    applicationReset: (state) => {
      state.applications = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applicationsGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.applications = action.payload.data.filter
          ? state.applications
          : action.payload;

        state.count = action.payload.data.total;
      })
      .addCase(applicationsGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.applications = null;
      });
  },
});

export const { applicationReset } = applicationsGetSlice.actions;

export default applicationsGetSlice.reducer;
