import { getDatabase, off, onValue, ref } from "firebase/database";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import left_arrow from "../../../images/left-arrow.svg";
import { chatsListManegerSingle } from "../../../store/chat/chatListMenegerSingleSlice/chatListMenegerSingleSlice";
import { сhatManagerSend } from "../../../store/chat/сhatManagerSendSlice/сhatManagerSendSlice";
import { truncateString } from "../../../utils/text-slice";
import ChatSidebar from "../chat-sidebar/chat-sidebar";
import { chatsManedgerList } from "./../../../store/chat/chatListMenegerSlice/chatListMenegerSlice";

function ListChatManager({ list }) {
  const { user } = useSelector((state) => state.profileGet);
  const [open, setOpen] = useState(false);
  const [chatRoom, setChatRoom] = useState("");
  const { id } = useParams();

  const dispatch = useDispatch();

  const onClick = async (e, id) => {
    try {
      setOpen((prevOpen) => !prevOpen); // Один вызов setOpen

      const res = await dispatch(chatsListManegerSingle(id));
      if (res.payload.status === 200) {
        const database = getDatabase();
        const userId = user?.data.user[0].id;
        const chatRoomId = res.payload.data.chat_room_id;
        if (userId && chatRoomId) {
          const knot = `chat_room/${userId}/${chatRoomId}`;
          const messagesRef = ref(database, knot);

          const unsubscribe = onValue(messagesRef, (snapshot) => {
            if (snapshot.exists()) {
              dispatch(chatsListManegerSingle(id));
            }
          });

          // Очистка слушателя при закрытии чата
          return () => {
            off(messagesRef);
          };
        }
      }
    } catch (error) {
      console.error("Error opening chat:", error);
    }
  };
  const { manager_single_chat } = useSelector(
    (state) => state.singleManagerChat
  );
  const sendApi = (message) => {
    dispatch(
      сhatManagerSend({ id: chatRoom, data: { content: message } })
    ).then((res) => {
      if (res.payload.status === 201) {
        dispatch(chatsListManegerSingle(chatRoom));
        dispatch(chatsManedgerList(id));
      }
    });
  };

  return (
    <div className="chat-collumn-one">
      {Object.keys(list).length !== 0 &&
        list.data.map((item) => {
          return (
            <div
              className={`chat-list-message chat-list-message-manager  d-flex align-items-center position-relative px-0`}
              onClick={(e) => {
                onClick(e, item.chat_room_id);
                setChatRoom(item.chat_room_id);
              }}
            >
              <div className="chat-list-content">
                <div className="chat__header">
                  <div className="chat__header--title ml-0">
                    <div>
                      {item?.chat_creator_name
                        ? item?.chat_creator_name
                        : item?.chat_creator_phone}
                    </div>
                  </div>
                </div>

                <div className="chat__text">
                  {truncateString(item.messages[0].content, 125)}
                </div>
                <span className="message-time">
                  {item.messages[0].created_at}
                </span>
              </div>
              <div className="d-flex justify-content-center chat-list-arrow">
                {item.unread_count !== 0 && (
                  <div className="chat-list-seen"></div>
                )}

                <img src={left_arrow} alt="" />
              </div>
            </div>
          );
        })}
      {open && (
        <ChatSidebar
          open={open}
          id={id}
          chatRoom={chatRoom}
          onClick={onClick}
          chats={manager_single_chat}
          send={sendApi}
        />
      )}
    </div>
  );
}

export default ListChatManager;
