import React from "react";
import { useSelector } from "react-redux";

function SinglePrice({ item }) {
  const { user } = useSelector((state) => state.profileGet);
  const renderContent = () => {
    switch (user?.data?.role) {
      case "Pro": {
        let price = "";
        if (item?.price_alls !== 0) {
          price = (
            <div className="detail-market__row">
              <div className="detail-market__col">
                <div className="detail-market__price-title">
                  Стоимость авто:
                </div>
                <div className="detail-market__price">
                  {item?.price_alls.toLocaleString("en-US").replace(/,/g, " ")}{" "}
                  ₽
                </div>
              </div>
              {item?.price_discount !== 0 && (
                <>
                  <div className="detail-market__col">
                    <div className="detail-market__price-two-title">
                      Цена для PRO
                    </div>
                    <div className="detail-market__price-two">
                      {item?.price_discount
                        .toLocaleString("en-US")
                        .replace(/,/g, " ")}{" "}
                      ₽
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        }

        if (item?.price_alls == 0) {
          price = (
            <>
              {item?.price_discount !== 0 && (
                <>
                  <div className="detail-market__row">
                    <div className="detail-market__col">
                      <div className="detail-market__price-title">
                        Стоимость авто:
                      </div>
                      <div className="detail-market__price">
                        {item?.price_discount
                          .toLocaleString("en-US")
                          .replace(/,/g, " ")}{" "}
                        ₽
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          );
        }
        return price;
      }
      case "Dealer": {
        let price = "";
        if (item?.price_alls !== 0) {
          price = (
            <div className="detail-market__row">
              <div className="detail-market__col">
                <div className="detail-market__price-title">
                  Стоимость авто:
                </div>
                <div className="detail-market__price">
                  {item?.price_alls.toLocaleString("en-US").replace(/,/g, " ")}{" "}
                  ₽
                </div>
              </div>
              {item?.price_discount !== 0 && (
                <>
                  <div className="detail-market__col">
                    <div className="detail-market__price-two-title">
                      Цена для PRO
                    </div>
                    <div className="detail-market__price-two">
                      {item?.price_discount
                        .toLocaleString("en-US")
                        .replace(/,/g, " ")}{" "}
                      ₽
                    </div>
                  </div>
                </>
              )}
              {item?.price !== 0 && (
                <>
                  <div className="detail-market__col">
                    <div className="detail-market__price-two-title">
                      Цена для поставщика
                    </div>
                    <div className="detail-market__price-two">
                      {item?.price.toLocaleString("en-US").replace(/,/g, " ")} ₽
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        }

        if (item?.price_alls == 0) {
          price = (
            <>
              {item?.price_discount !== 0 && (
                <div className="detail-market__row">
                  <div className="detail-market__col">
                    <div className="detail-market__price-title">
                      Стоимость авто:
                    </div>
                    <div className="detail-market__price">
                      {item?.price_discount
                        .toLocaleString("en-US")
                        .replace(/,/g, " ")}{" "}
                      ₽
                    </div>
                  </div>
                  {item?.price !== 0 && (
                    <>
                      <div className="detail-market__col">
                        <div className="detail-market__price-two-title">
                          Цена для поставщика
                        </div>
                        <div className="detail-market__price-two">
                          {item?.price
                            .toLocaleString("en-US")
                            .replace(/,/g, " ")}{" "}
                          ₽
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          );
        }

        return price;
      }
      case "User": {
        let price = "";
        if (item?.price_alls !== 0) {
          price = (
            <div className="detail-market__row">
              <div className="detail-market__col">
                <div className="detail-market__price-title">
                  Стоимость авто:
                </div>
                <div className="detail-market__price">
                  {item?.price_alls.toLocaleString("en-US").replace(/,/g, " ")}{" "}
                  ₽
                </div>
              </div>
            </div>
          );
        }

        return price;
      }
      default: {
        let price = "";
        if (item?.price !== 0) {
          price = (
            <div className="detail-market__row">
              <div className="detail-market__col">
                <div className="detail-market__price-title">
                  Стоимость авто:
                </div>
                <div className="detail-market__price">
                  {item?.price.toLocaleString("en-US").replace(/,/g, " ")} ₽
                </div>
              </div>
            </div>
          );
        }

        return price;
      }
    }
  };

  return renderContent();
}

export default SinglePrice;
