import { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

function PrivateRoutes({ user, roles }) {
  const navigate = useNavigate();
  const location = useLocation();
  const guestLocation = ["/", "/applications-guest"];

  useEffect(() => {
    if (!user && !guestLocation.includes(location.pathname)) {
      navigate("/login");
    } else if (user && !roles.includes(user.role)) {
      navigate("/403");
    }
  }, [user, roles, navigate]); // Зависимости правильно указаны

  return <Outlet />; // Рендер дочерних маршрутов, если проверка пройдена
}

export default PrivateRoutes;
