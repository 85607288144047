import React from "react";

function EditIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 4.02501C12.7505 3.94276 12.7347 3.86122 12.7036 3.78507C12.6725 3.70892 12.6267 3.63966 12.5687 3.58126L9.91875 0.93126C9.86034 0.873335 9.79109 0.827506 9.71494 0.796403C9.63879 0.765301 9.55725 0.749535 9.475 0.75001C9.39274 0.749535 9.3112 0.765301 9.23505 0.796403C9.15891 0.827506 9.08965 0.873335 9.03125 0.93126L7.2625 2.70001L0.431245 9.53126C0.373319 9.58966 0.327491 9.65892 0.296388 9.73507C0.265285 9.81122 0.24952 9.89276 0.249995 9.97501V12.625C0.249995 12.7908 0.315843 12.9497 0.433053 13.067C0.550264 13.1842 0.709235 13.25 0.874995 13.25H3.525C3.61245 13.2548 3.69993 13.2411 3.78176 13.2099C3.86359 13.1786 3.93794 13.1306 4 13.0688L10.7937 6.23751L12.5687 4.50001C12.6258 4.43944 12.6723 4.36972 12.7062 4.29376C12.7123 4.24394 12.7123 4.19358 12.7062 4.14376C12.7092 4.11467 12.7092 4.08535 12.7062 4.05626L12.75 4.02501ZM3.26875 12H1.5V10.2313L7.70625 4.02501L9.475 5.79376L3.26875 12ZM10.3562 4.91251L8.5875 3.14376L9.475 2.26251L11.2375 4.02501L10.3562 4.91251Z"
        fill="#8D8796"
      />
    </svg>
  );
}

export default EditIcon;
